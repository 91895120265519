body:before {
	background: none;
}

body,
html {
	overflow-x: hidden;
	color: #737373;
}


body::-webkit-scrollbar,
html::-webkit-scrollbar {
	display: none;
}

body.auth-wrapper:before {
	background: -webkit-gradient(linear, left top, right bottom, from(#D7BBEA), to(#65A8F1));
}

body.auth-wrapper.login:before {
	background:
		inherit;
}

body.auth-wrapper.login {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


body.signup-wrapper.signup:before {
	background:
		inherit;
}

body.signup-wrapper {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


body.auth-box-w {
	-webkit-animation-name: fadeUp;
	animation-name: fadeUp;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

body.background-cover-a {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/benjamin-davies-mqN-EV9rNlY-unsplash.jpg);
}

body.background-cover-b {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/holly-mandarich-UVyOfX3v0Ls-unsplash.jpg);
}

body.background-cover-c {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/jonathan-forage-1azAjl8FTnU-unsplash.jpg);
}

body.background-cover-d {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/josh-hild-XKNoL2GQg5o-unsplash.jpg);
}

body.background-cover-e {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/manuel-meurisse-QHeAAc3y76Y-unsplash.jpg);
}

body.background-cover-f {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/wojciech-then-DijA5f0voGQ-unsplash.jpg);
}

body.background-cover-g {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/jeff-finley-AM015l53CLE-unsplash.jpg);
}

body.background-cover-h {
	background-image: url(https://cdn-images.tripworks.com/images/login-backgrounds/joshua-earle-wFWQmOyfkkM-unsplash.jpg);
}


p {
	margin-top: 0;
	margin-bottom: 0.5rem;
	color: #737373;
}

.b-form-btn-label-control.form-control>.btn {
	padding-left: 10px;
	color: #b5b5b5;
}

.text-muted {
	color: #899298 !important;
}

.text-strike {
	text-decoration: line-through;
}

li {
	color: #737373;
}

.pad-t-0 {
	padding-top: 0px !important;
}

.pad-t-5 {
	padding-top: 5px !important;
}

.pad-t-10 {
	padding-top: 10px !important;
}

.pad-t-15 {
	padding-top: 15px !important;
}

.pad-t-20 {
	padding-top: 20px !important;
}

.pad-t-30 {
	padding-top: 30px !important;
}

.pad-t-40 {
	padding-top: 40px !important;
}

.pad-t-50 {
	padding-top: 50px !important;
}

.pad-t-100 {
	padding-top: 100px !important;
}

.pad-b-0 {
	padding-bottom: 0px !important;
}

.pad-b-5 {
	padding-bottom: 5px !important;
}

.pad-b-10 {
	padding-bottom: 10px !important;
}

.pad-b-15 {
	padding-bottom: 15px !important;
}

.pad-b-20 {
	padding-bottom: 20px !important;
}

.pad-b-30 {
	padding-bottom: 30px !important;
}

.pad-b-40 {
	padding-bottom: 40px !important;
}

.pad-b-50 {
	padding-bottom: 50px !important;
}

.pad-b-100 {
	padding-bottom: 100px !important;
}

.pad-tb-0 {
	padding-top: 0px;
	padding-bottom: 0px;
}

.pad-tb-5 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.pad-tb-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.pad-tb-15 {
	padding-top: 15px;
	padding-bottom: 15px;
}

.pad-tb-20 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.pad-tb-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.pad-tb-340 {
	padding-top: 40px;
	padding-bottom: 40px;
}


.pad-l-0 {
	padding-left: 0px !important;
}

.pad-l-5 {
	padding-left: 5px !important;
}

.pad-l-10 {
	padding-left: 10px !important;
}

.pad-l-15 {
	padding-left: 15px !important;
}

.pad-l-20 {
	padding-left: 20px !important;
}

.pad-l-30 {
	padding-left: 30px !important;
}

.pad-l-40 {
	padding-left: 40px !important;
}

.pad-l-50 {
	padding-left: 50px !important;
}

.pad-r-0 {
	padding-right: 0px !important;
}

.pad-r-5 {
	padding-right: 5px !important;
}

.pad-r-10 {
	padding-right: 10px !important;
}

.pad-r-15 {
	padding-right: 15px !important;
}

.pad-r-20 {
	padding-right: 20px !important;
}

.pad-r-30 {
	padding-right: 30px !important;
}

.pad-r-40 {
	padding-right: 40px !important;
}

.pad-r-50 {
	padding-right: 50px !important;
}

.pad-lr-0 {
	padding-left: 0px;
	padding-right: 0px;
}

.pad-lr-5 {
	padding-left: 5px;
	padding-right: 5px;
}

.pad-lr-10 {
	padding-left: 10px;
	padding-right: 10px;
}

.pad-lr-15 {
	padding-left: 15px;
	padding-right: 15px;
}

.pad-lr-20 {
	padding-left: 20px;
	padding-right: 20px;
}

.pad-lr-30 {
	padding-left: 30px;
	padding-right: 30px;
}

.pad-lr-40 {
	padding-left: 40px;
	padding-right: 40px;
}

.pad-lr-50 {
	padding-left: 50px;
	padding-right: 50px;
}

.pad-0 {
	padding: 0px !important;
}

.pad-5 {
	padding: 5px !important;
}

.pad-10 {
	padding: 10px !important;
}

.pad-15 {
	padding: 15px !important;
}

.pad-20 {
	padding: 20px !important;
}

.pad-30 {
	padding: 30px !important;
}

.pad-40 {
	padding: 40px !important;
}

.pad-50 {
	padding: 50px !important;
}

.pad-100 {
	padding: 100px !important;
}


.status-pill.small {
	width: 7px;
	height: 7px;
}

.light {
	font-weight: 100;
}

.margin-auto {
	margin: auto;
}

.margin-t-0 {
	margin-top: 0px !important;
	;
}

.margin-t-5 {
	margin-top: 5px !important;
	;
}

.margin-t-10 {
	margin-top: 10px !important;
	;
}

.margin-t-15 {
	margin-top: 15px !important;
	;
}

.margin-t-20 {
	margin-top: 20px !important;
	;
}

.margin-t-25 {
	margin-top: 25px !important;
	;
}

.margin-t-30 {
	margin-top: 30px !important;
	;
}

.margin-t-40 {
	margin-top: 40px !important;
	;
}

.margin-t-50 {
	margin-top: 50px !important;
	;
}

.margin-b-0 {
	margin-bottom: 0px !important;
}

.margin-b-5 {
	margin-bottom: 5px !important;
}

.margin-b-10 {
	margin-bottom: 10px !important;
}

.margin-b-15 {
	margin-bottom: 15px !important;
}

.margin-b-20 {
	margin-bottom: 20px !important;
}

.margin-b-30 {
	margin-bottom: 30px !important;
}

.margin-b-40 {
	margin-bottom: 40px !important;
}

.margin-b-50 {
	margin-bottom: 50px !important;
}

.margin-tb-0 {
	margin-top: 0px;
	margin-bottom: 0px;
}

.margin-tb-5 {
	margin-top: 5px;
	margin-bottom: 5px;
}

.margin-tb-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.margin-tb-15 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.margin-tb-20 {
	margin-top: 20px;
	margin-bottom: 20px;
}

.margin-tb-30 {
	margin-top: 30px;
	margin-bottom: 30px;
}

.margin-tb-40 {
	margin-top: 40px;
	margin-bottom: 40px;
}


.margin-l-0 {
	margin-left: 0px !important;
}

.margin-l-5 {
	margin-left: 5px !important;
}

.margin-l-10 {
	margin-left: 10px !important;
}

.margin-l-15 {
	margin-left: 15px !important;
}

.margin-l-20 {
	margin-left: 20px !important;
}

.margin-l-25 {
	margin-left: 25px !important;
}

.margin-l-30 {
	margin-left: 30px !important;
}

.margin-l-35 {
	margin-left: 35px !important;
}

.margin-l-40 {
	margin-left: 40px !important;
}

.margin-l-45 {
	margin-left: 40px !important;
}

.margin-l-50 {
	margin-left: 50px !important;
}

.margin-r-0 {
	margin-right: 0px !important;
}

.margin-r-5 {
	margin-right: 5px !important;
}

.margin-r-10 {
	margin-right: 10px !important;
}

.margin-r-15 {
	margin-right: 15px !important;
}

.margin-r-20 {
	margin-right: 20px !important;
}

.margin-r-30 {
	margin-right: 30px !important;
}

.margin-r-40 {
	margin-right: 40px !important;
}

.margin-lr-0 {
	margin-left: 0px;
	margin-right: 0px;
}

.margin-lr-5 {
	margin-left: 5px;
	margin-right: 5px;
}

.margin-lr-10 {
	margin-left: 10px;
	margin-right: 10px;
}

.margin-lr-15 {
	margin-left: 15px;
	margin-right: 15px;
}

.margin-lr-20 {
	margin-left: 20px;
	margin-right: 20px;
}

.margin-lr-20 {
	margin-left: 20px;
	margin-right: 20px;
}

.margin-lr-30 {
	margin-left: 30px;
	margin-right: 30px;
}

.margin-lr-40 {
	margin-left: 40px;
	margin-right: 40px;
}

.margin-0 {
	margin: 0px !important;
}

.margin-10 {
	margin: 10px;
}

.margin-15 {
	margin: 15px;
}

.margin-20 {
	margin: 20px;
}

.margin-30 {
	margin: 30px;
}

.margin-40 {
	margin: 40px;
}

.b-b {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.b-t {
	border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.smaller,
.text-small {
	font-size: .9em;
}



a:hover {
	text-decoration: none;
}

a.underline:hover {
	text-decoration: underline !important;
	cursor: pointer !important;
}

.controls-above-table button:focus {
	outline: none;
	box-shadow: 0 0 0 1px rgb(42 47 69 / 10%), 0 2px 5px 0 rgb(42 47 69 / 8%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%), 0 0 0 0 transparent;
}

.controls-above-table button.dropdown-toggle-split {
	border-left: 1px solid #2f8ff7;
}

.xdropdown-item {
	padding-top:12px;
	padding-bottom:10px;
	padding-left:20px;
	padding-right:20px;
	text-transform:uppercase;
	font-weight:400;
}

.dropdown-item {display: flex;margin: 0px;padding: 0;padding-left: .75rem;padding-right: 1.5rem;line-height: 2;flex-wrap: wrap;/* width: 100%; *//* white-space: nowrap; */}
.dropdown-icon {width: 25px;text-align: center;margin-right: 10px;color: #919191;border-right: 1px solid #e0e0e0;padding-right: 10px;}

.active .dropdown-icon {color:white;}



#dt_wrapper td.money,
td.money,
th.money {
	text-align: right;
}

#dt_wrapper td.percent,
td.percent,
th.percent {
	text-align: right;
}

#dt_wrapper #dt_filter {
	position: absolute;
	top: -50px;
	left: 0px;
}

#dt_wrapper #dt_filter input {
	border-radius: 30px;
	border: none;
	background-color: rgba(121, 138, 185, 0.07);
	padding: 8px 15px 8px 35px;
}

#dt_wrapper #dt_filter:before {
	font-family: 'Font Awesome 5 Pro' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	left: 24px;
	top: 18px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 14px;
	content: "\f002";
	color: rgb(0 0 0 / 35%);
}

#dt_wrapper tr:hover {
	cursor: pointer;
}

#dt_wrapper td.center {
	text-align: center;
}


#card-element.StripeElement {
	box-sizing: border-box;
	height: 40px;
	margin-bottom: 20px;
	padding: 10px 12px;
	border: 2px solid #dde2ec;
	border-radius: 4px;
	background-color: white;
}


body,
body.auth-wrapper .all-wrapper {
	padding: 0px;
}

.all-wrapper {
	box-shadow: none;
	-webkit-box-shadow: none;
	max-width: inherit;
	/* height: -webkit-fill-available; */
	/* height: 100vh; */
}

.modalTripBuilder .modal-body {
	/* padding: 0px; */
	padding-bottom: 0px;
	padding-left: 10px;
	padding-right: 10px;
	overflow: hidden;
}

.modalTripBuilder .modal-body .rebook {
	/* padding-left: 40px; */
	/* padding-right:20px; */
	padding-top: 0px;
	margin-top: 20px;
}

.modalTripBuilder .rebook {
	padding-left: 10px;
}


input[type="color"] {
	max-width: 50px;
}

code {
	box-shadow: none;
}

.logged-user-w.avatar-inline .logged-user-avatar-info {
	justify-content: left;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .tab-label {
	font-size: 1em;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link.active .tab-label {
	font-size: 1.08em;
}


.auth-wrapper {
	padding-top: 20px;
}

.auth-box-w .alert {
	margin: 20px;
}

.alert {
	display: block;
	white-space: normal;
}



.element-box {
	/* -webkit-animation-name: none; */
	/* animation-name: none; */
	/* -webkit-animation-duration: .5s; */
	/* animation-duration: .5s; */
	width: 100%;
	position: relative;
	/* transition-duration: .5s; */
	/* animation-name: inherit; */
	/* display: flex; */
}

.justify-content-center {
	justify-content: center;
}

.element-box.bordered {
	border: 1px solid #f1f1f1;
}


.element-box.borderless {
	margin: 10px;
	padding: 10px;
}

.custom-file {
	position: relative;
	display: initial;
	vertical-align: top;
}

.custom-file-input {
	opacity: inherit;
}

.modal-fullscreen .modal-dialog {
	max-width: 100%;
	margin: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100vh;
	display: flex;
	position: fixed;
	z-index: 100000;
}

.modal.modal-fullscreen .modal-dialog .modal-content {
	border-radius: 0px;
}

.vich-image {
	vertical-align: top;
}

.vich-image img {
	border-radius: 4px;
	height: 100px;
	width: 100px;
	display: inline-block;
	border: 1px solid #dde2eb;
	margin-left: 20px;
	padding: 3px;
}

.vich-image>a>img {
	display: none;
}

.custom-file-label {
	position: relative;
	right: inherit;
	bottom: inherit;
	z-index: 1;
	height: inherit;
	padding: 0px;
	line-height: inherit;
	color: #495057;
	background-color: #fff;
	border: 2px solid #dde2ec;
	border-radius: 4px;
	cursor: pointer;
	border: none;
}


.custom-file-label::after {
	position: inherit;
	top: inherit;
	right: inherit;
	bottom: inherit;
	z-index: 3;
	display: block;
	height: calc(calc(2.1rem + 4px) - 2px * 2);
	line-height: 1.5;
	color: #495057;
	content: "Browse";
	background-color: #e9ecef;
	border-left: 2px solid #dde2ec;
	border-radius: 0 4px 4px 0;
}


.wide .big-error-w {
	width: inherit;
	box-shadow: none;
}

.error-wrapper.wide {
	padding-top: 0px;
}

.setup .element-box {
	min-height: 260px;
}

.setup .element-box li {
	list-style: none;
	/* font-size:.9em; */
}

.setup .element-box ul {
	padding-inline-start: 10px;
	position: relative;
	margin-bottom: 0px;
}

#setup-left-nav.menu-side-left ul.main-menu ul.sub-menu li.active {
	font-weight: bold;
}


.table-hover.table-striped tbody tr {
	transition: background-color 0.5s ease;
}


.table-hover.table-striped tbody tr.even:hover {
	background-color: rgba(18, 95, 142, 0.04);
}

.table-hover.table-striped tbody tr.odd:hover {
	background-color: rgba(18, 95, 142, 0.04);
}

.tw-payment-form i.fa-calculator {
	font-size: 18px;
}


.card-grid-icon img {
	width: 30px;
}


#tripBuilderModal .modal-body {
	padding: 0px;
	overflow: inherit;
}

#tripBuilderModal #catalog,
#tripBuilderModal #categories {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
}

#tripBuilderModal .modal-body .checkout .checkout-right {
	/* padding-left:20px; */
	/* padding-right: 40px; */
	min-height: 450px;
}

.show-nav-head .actions-left {
	display: -webkit-box;
	display: -ms-flexbox;
	flex-grow: 1;
}

.show-nav-head .actions-right {
	display: -webkit-box;
	display: -ms-flexbox;
	flex-basis: 200px;
	flex-grow: 0;
	margin-right: 20px;
}

.show-nav-head .aeh-actions {
	line-height: 100%;
	vertical-align: middle;
	text-align: right;
	width: 100%;
}

.show-nav-head .aeh-actions a {
	margin-right: 5px;
}

.show-nav-head .aeh-actions li>a {
	color: white;
}

.show-nav-head .actions-left a {
	margin-top: 3px;
}

.show-nav-head .separate {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	padding-right: 15px;
	margin-right: 5px;
}

.show-nav-head a.white i {
	color: white;
}

.auth-description {
	color: #999;
	font-weight: 300;
	font-size: 0.9rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	/* display: block; */
	padding-left: 80px;
	padding-right: 80px;
}

.select2.select2-container .select2-selection--single {
	height: 37px;
	padding-top: 4px;
	border: 2px solid #dde2ec;
	padding-left: 4px;
}

.select2-dropdown {
	border: 2px solid #dde2ec;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
	outline: 0;
	border-color: #047bf8;
	border-width: 2px;
	border-radius: 4px;
}

body .select2.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 5px;
	width: 30px;
}

body .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-width: 6px 5px 0 5px;
	border-color: #dde2ec transparent transparent;
}

body .select2-container--default.select2-container--focus .select2-selection--multiple {
	border: 2px solid #dde2ec;
}

body .select2-container--default .select2-selection--multiple {
	border: 2px solid #dde2ec;
}

.form-group .form-check+small {
	margin-left: 45px;
}

span.select2-container {
	display: block;
}

textarea {
	min-height: 125px;
	background-color: white;
}


.payments-modal .buttons .label {
	min-height: 40px;
	padding-top: 10px;
	color: black;
}

.modal-body.split {
	padding: 0px;
}

.modal-split-top {
	padding-top: 20px;
	text-align: center;
	text-align: center;
	min-height: 80px;
}

.modal-split-top img {
	margin: 0px auto;
	vertical-align: middle;
	padding-bottom: 20px;
	padding-top: 20px;
}

.text-color-red {
	color: #ce1e1e;
}

#auditTable td.money {
	text-align: left;
}

.audit-detail .badge {
	text-align: left;
	white-space: normal;
}

input.datepicker {
	padding: 0.375rem 0.75rem;
	width: 170px;
}

.datepicker.dropdown-menu {
	border-color: #e5e5e5;
}


.modal-split-bottom {
	padding: 40px 50px 40px;
	background-image: url(../images/template/onboarding-gradient-hor.png);
	background-size: 100% auto;
	background-position: top center;
	background-repeat: no-repeat;
	/* background-color: #00008c08; */
}

.modal-split-title {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	color: #047bf8;
	margin-bottom: 10px;
}

.hide-menu .menu-w {
	display: none !important;
}

.hide-menu .menu-mobile {
	display: none !important;
}

.modal-split-caption {
	color: rgba(69, 76, 96, 0.49);
	font-weight: 300;
	font-size: 0.99rem;
	margin-bottom: 20px;
	margin-top: 0px;
}

.nav.upper.small {
	font-size: .8rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.nav.upper.small .nav-link {
	padding-bottom: 10px;
	margin-right: 1rem;
}


.btn-default {
	background-color: #fff;
	border-radius: 2px;
	/* box-shadow: 0 0 0 1px rgba(42,47,69,.05), 0 2px 5px 0 rgba(42,47,69,.04), 0 1px 1.5px 0 rgba(0,0,0,.03), 0 1px 2px 0 rgba(0,0,0,.04), 0 0 0 0 transparent; */
	color: #4f566b;
	cursor: pointer;
	text-decoration: none;
	vertical-align: middle;
	white-space: nowrap;
	word-break: keep-all;
	transition: box-shadow .08s ease-in, color .08s ease-in;
	border: 1px solid #ececec;
}

.btn-danger {
	/* box-shadow: 0 0 0 1px rgba(42, 47, 69, .1), 0 2px 5px 0 rgba(42, 47, 69, .08), 0 1px 1.5px 0 rgba(0, 0, 0, .07), 0 1px 2px 0 rgba(0, 0, 0, .08), 0 0 0 0 transparent; */
	cursor: pointer;
	text-decoration: none;
	vertical-align: middle;
	white-space: nowrap;
	word-break: keep-all;
	transition: box-shadow .08s ease-in, color .08s ease-in;
	border: 1px solid red;
}

.modal-header {
	background-color: #ebeef321;
	border-bottom: 1px solid #2700ff14;
}

.modal-header-container {
	display: flex;
	flex-direction: column;
}

.modal-header .close-label {
	margin-right: 10px;
	color: #C26C6C;
	letter-spacing: 1px;
	font-weight: 500;
	font-size: 0.81rem;
	margin-top: 4px;
}


.modal.no-header .modal-header {
	background-color: inherit;
	border-bottom: inherit;
	padding: 0px;
}


.modal-middle-row {
	display: flex;
	padding: 0;
}

.modal-left-side {
	width: 30%;
	min-width: fit-content;
	flex-shrink: 1;
}

.modal-right-side {
	flex-grow: 1;
}

.modal.no-padding .modal-body {
	padding: 0px;
}

.modal.no-header .modal-content {
	/* border-radius: 8px; */
	/* overflow: hidden; */
}

.table span.secondary-experience-name {
	display: block;
	margin-left: 25px;
	font-size: .9em;
	opacity: .7;
}

.table div.with-secondary-experience-name span.primary-experience-name {
	font-weight: bold;
}

.pretty .state label i {
	display: inline;
	margin-left: 5px;
}

.checkbox-selector .pretty {
	margin-right: 0px;
}

body .pretty {
	white-space: inherit;
	/* display: flex; */
}

body .pretty .state label:after,
body .pretty .state label:before {
	top: 0;
}

body .pretty .state label {
	line-height: 1.3;
	text-indent: 0;
	padding-left: 1.7em;
	cursor: pointer;
}

.pretty {
	white-space: inherit;
}

.pretty p.text-muted {
	margin-left: 25px;
	margin-top: 3px;
	line-height: 1.4;
}

.dataTables_wrapper .row:first-child {
	margin-top: 0px;
}

.dataTables-selected {
	background-color: #e0e0e0 !important;
}

.payments-modal .second-row a {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.payments-modal .second-row i {
	font-size: 1.5em;
}

.payments-modal .buttons.second-row .label {
	font-size: 10px;
}

.btn-default:hover {
	background-color: rgb(237 237 237);
	/* box-shadow: 0 0 0 1px rgba(42, 47, 69, .1), 0 2px 5px 0 rgba(42, 47, 69, .1), 0 3px 9px 0 rgba(42, 47, 69, .08), 0 1px 1.5px 0 rgba(0, 0, 0, .08), 0 1px 2px 0 rgba(0, 0, 0, .08); */
	color: #2a2f45;
}

header.modal-header {
	-webkit-box-align: inherit;
	display: block;
	align-items: inherit;
	-webkit-box-pack: inherit;
	justify-content: inherit;
}

.modal-header span {
	margin-right: inherit;
}

.modal-content header h5 {
	color: #047bf8;
	/* padding-left: 10px; */
	margin-top: 10px;
	margin-bottom: 0px;
}

.modal-content header p {
	color: #047bf8;
	/* padding-left: 10px; */
	margin-top: 0px;
	margin-bottom: 0px;
	display: block;
}

.modal-content .close,
.modal-content .btn-close,
.btn-close-modal {
	position: absolute;
	top: 20px;
	right: 30px;
	float: none;
	z-index: 999;
	color: #1071da;
	opacity: 1;
	text-shadow: none;
	-webkit-appearance: none;
	border: none;
	background-color: inherit;
	font-size: 36px;
	padding: 0px;
	text-align: inherit;
	margin: inherit;
	font-weight: 100;
	font-size: 16px;
}

.modal-content header .btn-close,
.btn-close-modal {
	top: 25px;
	background: transparent;
	/* max-width: 15px; */
	display: flex;
}

.modal-content header .btn-close span,
.btn-close-modal span {
	/* color:inherit; */
}

.modal-content .close:hover span.line:after,
.modal-content .btn-close:hover span.line:after,
.btn-close-modal:hover span.line:after {
	background-color: #047bf8;
	width: 100%;
}

.modal-content .close span.line:after,
.modal-content .btn-close span.line:after,
.btn-close-modal span.line:after {
	background-color: #047bf8;
	width: 0%;
	height: 2px;
	position: absolute;
	left: 0px;
	bottom: -8px;
	content: "";
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	z-index: 100;
}



.b-calendar .form-control:focus {
	border-color: white;
}

body .form-control.focus {
	color: inherit;
	background-color: inherit;
	border: 2px solid #dde2ec;
	box-shadow: none;
}

.b-form-btn-label-control button:focus {
	outline-width: 0px;
}

.b-calendar .form-control {
	border-color: white;
}

.b-calendar-nav {
	justify-content: space-between;
	padding-top: 5px;
	padding-bottom: 5px;
}

.b-calendar .b-calendar-grid-body .col[data-date] .btn:focus {
	outline: none;
	box-shadow: none;
}

.tab-required-warning {
	padding-right: 5px;
}


.vs__selected-options {
	min-height: 31px;
}

.v-select .vs__dropdown-toggle {
	border: 2px solid #dde2ec;
}

body .b-form-datepicker button {
	/* background-color:white; */
	/* padding:0px; */
	/* margin:0px; */
	/* color: #c5c5c5; */
}

body .b-form-datepicker.form-control label {
	min-height: inherit;
	margin: 0px;
	margin-left: 5px;
	white-space: nowrap;
	margin-top: 2px;
}



.modal .back {
	border: none;
	background-color: transparent;
	font-size: 0.9rem;
	color: #a5a5a5;
	bottom: 55px;
	position: absolute;
	z-index: 999;
	cursor: pointer;
	letter-spacing: 1px;
	left: 75px;
}


.modal .back:before {
	font-family: "Font Awesome 5 Pro";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 15px;
	color: #8e939c;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	content: "\f060";
	left: -20px;
}

.modal .trip .content-box,
.modal .customer .content-box {
	margin-top: 0px;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 0px;
	/* height: 100%; */
}

.os-progress-bar .bar-labels {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 5px;
}

.os-progress-bar .bar-label-left span {
	margin-right: 5px;
}

.os-progress-bar .bar-labels span {
	font-size: 0.72rem;
}

.os-progress-bar .bar-label-left span.positive {
	color: #619B2E;
}

.os-progress-bar .bar-label-left span {
	margin-right: 5px;
}


.os-progress-bar .bar-label-right span.info {
	color: #456CF9;
}

.os-progress-bar .bar-label-right span {
	margin-left: 5px;
}

.os-progress-bar.blue .bar-level-1,
.os-progress-bar.primary .bar-level-1 {
	background-color: #F2F2F2;
}

.os-progress-bar .bar-level-1,
.os-progress-bar .bar-level-2,
.os-progress-bar .bar-level-3 {
	border-radius: 12px;
	height: 15px;
}

.os-progress-bar.blue .bar-level-2,
.os-progress-bar.primary .bar-level-2 {
	background-color: #65affd;
}

.os-progress-bar .bar-level-1,
.os-progress-bar .bar-level-2,
.os-progress-bar .bar-level-3 {
	border-radius: 12px;
	height: 15px;
}

.os-progress-bar.blue .bar-level-3,
.os-progress-bar.primary .bar-level-3 {
	background-color: #0362c6;
}

.os-progress-bar .bar-level-1,
.os-progress-bar .bar-level-2,
.os-progress-bar .bar-level-3 {
	border-radius: 12px;
	height: 15px;
}


.cursor-point,
.pointer,
.cursor-pointer {
	cursor: pointer;
}

input.split-apply {
	width: 60px;
	display: inline;
}

input.split-parts {
	width: 60px;
	display: inline;
}

.tw-payment-form .calculator {
	background-color: #89a6c514;
	border-radius: 8px;
	padding-top: 10px;
	font-size: 14px;
	padding-bottom: 10px;
}

.tw-payment-form .calculator button .value {
	display: block;
	font-size: 12px;
	color: #a9a9a9;
	margin-top: 5px;
}

.tw-payment-form .calculator button {
	position: relative;
}

.tw-payment-form .calculator button i {
	position: absolute;
	right: 2px;
	bottom: 2px;
}


button i+span {
	margin-left: 5px;
}

button:focus {
	outline: none;
}

.modal .back:focus {
	outline: none;
}

.payment-modal .buttons a {
	padding-left: 10px;
	padding-right: 10px;
	padding: 2rem;
	text-align: center;
	text-decoration: none;
	display: block;
	color: #3E4B5B;
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.payment-modal .buttons a .label {
	display: block;
	font-size: 0.63rem;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.4);
	letter-spacing: 1px;
}

.payment-modal .buttons a:hover {
	-webkit-transform: translateY(-5px) scale(1.02);
	transform: translateY(-5px) scale(1.02);
	-webkit-box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
	box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
}

.payment-modal .buttons.first-row {
	margin-bottom: 10px;
}

.payment-modal .buttons.second-row {
	margin-bottom: 30px;
}

.payment-modal .buttons.second-row a {
	padding-left: 10px;
	padding-right: 10px;
	padding: 1rem;
}

#bt-bulk-edit-save {
	vertical-align: middle;
	line-height: 37px;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
	text-align: center;
}

body .daterangepicker .ranges {
	padding: 0px;
}

body .daterangepicker {
	min-width: inherit;
}

body .daterangepicker thead tr:first-child th {
	text-align: center;
}

body .daterangepicker .calendar-table {
	padding-top: 0px;
}

xmp {
	whitespace: inherit;
}

.grid-parent-wrapper {
	top: 50px;
	bottom: 0px;
	right: 0px;
	position: absolute;
	left: 0px;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.grid-sizer {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.content-box.grid-sizer {
	margin-top: 0px;
}

.element-wrapper.grid-sizer {
	padding-bottom: 0px;
}

.element-box.grid-sizer {
	margin-bottom: 0px;
	min-height: 500px;
}

.ag-body-viewport {
	min-height: 250px;
}


body .element-wrapper div.daterangepicker table tr th select {
	border: 1px solid white;
	border-radius: 3px;
	font-size: 14px;
}

li.vs__dropdown-option {
	color: #979797;
}

li.vs__dropdown-option.vs__dropdown-option--highlight {
	color: white;
}


.select2-container {
	width: 100% !important;
}

select.select2 {
	-webkit-appearance: none;
}


.element-content {
	width: 100%;
	/* margin-right: 50px; */
}

.element-help {
	margin-right: 250px;
	flex-shrink: 0;
}

.help-active .content-box {
	padding-right: 300px;
}

.content-box {
	transition-duration: .5s;
	transition-property: padding-right;
}

ul.no-bullet {
	list-style: none;
}

ul.no-bullet i.bullet {
	margin-right: 13px;
	margin-left: -25px;
}


table.table th.reorder-column {width:10px;padding-right:0px;}
table.table th.reorder-column:after {content:""}

div.dt-rowReorder-float-parent {
  table-layout: fixed;
  outline: 2px solid rgb(10, 89, 203);
  z-index: 2001;
  position: absolute !important;
  overflow: hidden;
  border-radius: 3px;
}
div.dt-rowReorder-float-parent table.dt-rowReorder-float {
  opacity: 0.9;
  background-color: white;
  margin: 0 !important;
}

div.dt-rowReorder-float-parent.drop-not-allowed {
  cursor: not-allowed;
}

tr.dt-rowReorder-moving {
  outline: 2px solid #888;
  outline-offset: -2px;
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}

table.dataTable td.reorder {
  text-align: center;
  cursor: move;
}

div.dt-rowReorder-float-parent {
  outline-color: rgb(110, 168, 254);
}
div.dt-rowReorder-float-parent table.dt-rowReorder-float {
  background-color: var(--dt-html-background);
}
tr.dt-rowReorder-moving {
  outline-color: #aaa;
}




.filter-form {
	display: none;
}

.datatable-filter-box .filter-box-summary.hidden {
	display: none;
}

.datatable-filter-box .filter-box-actions.hidden {
	display: none;
}

.datatable-panel.hidden {
	display: none;
}

.datatable-view-box .view-item {
	cursor: pointer;
	transition: all 0.3s ease;
	border-radius: 4px;
	padding: 5px;
	text-align: left;
	/* padding-bottom: 3px; */
	padding: 10px;
}

.datatable-view-box .view-item:hover {
	background-color: #047af821;
}

.datatable-panel {
	background-color: #f9f9f9;
	box-shadow: 0 4px 17px 6px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	z-index: 25;
	border: 1px solid #e9f3ff;
	padding: 15px;
}


.controls-above-table .datatable-view-box {
	position: absolute;
	top: 30px;
	/* left: 10px; */
	min-width: 250px;
	max-height: 300px;
	overflow: hidden;
	overflow-y: scroll;
	text-align: left;
}

.datatable-filter-box {
	position: absolute;
	top: 45px;
	right: 0px;
	width: 600px;
	height: 300px;
}

.dropdown-item span.subtitle {
	display: block;
	margin-left: 35px;
	width: 100%;
}

.controls-above-table .dropdown-item i {
	min-width: 20px;
}

.badge-light {
	background-color: #e4e4e4;
}

.badge.timeslot-status-1 {
	background-color: #c5f0c0;
	color: #166e0c;
	border: 1px solid #1b850f;
	font-weight: 300;
}

.badge.timeslot-status-2 {
	background-color: #cce4ff;
	color: #0356ad;
	border: 1px solid #0362c6;
	font-weight: 300;
}

.badge.timeslot-status-3 {
	background-color: #e9f5e7;
	color: #777b77;
	border: 1px solid #cacaca;
	font-weight: 300;
}

.badge.timeslot-status-4 {
	background-color: #f8ebeb;
	/* color: white; */
	border: 1px solid #ff9898;
	font-weight: 300;
}

.badge.timeslot-status-4.text-strike {
	margin-right: 5px;
	/* Adjust the value as needed */
}

.badge.timeslot-status-1.channel {
	margin-right: 5px;
}

.badge.badge-support-tier-1 {
	background-color: #c5f0c0;
	color: #166e0c;
	border: 1px solid #1b850f;
	font-weight: 300;
}

.badge.badge-support-tier-2 {
	background-color: #ffcccc;
	color: #ad0303;
	border: 1px solid #c60303;
	font-weight: 500;
}

.badge-1x {
	font-size: 1rem;
}

.badge.badge-support-tier-3 {
	background-color: #f7f9de;
	color: #7b7a77;
	border: 1px solid #c1ce00;
	font-weight: 300;
}

.badge.badge-support-tier-4 {
	background-color: #3363ce2e;
	color: #0d47a0;
	border: 1px solid #ababab;
	font-weight: 300;
}


#setup-left-nav.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a {
	padding-left: 24px;
}

#setup-left-nav.menu-side-left ul.main-menu ul.sub-menu li {
	padding-left: 0px;
	padding-right: 10px;
}

#setup-left-nav.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu:first-child {
	padding-left: 20px;
}

#setup-left-nav.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a i {
	position: absolute;
	left: 0px;
	top: 8px;
}

.controls-above-table {
	position: relative;
}

.controls-above-table a {
	cursor: pointer;
}

.controls-above-table a.active:not([href]):not([tabindex]):hover,
.controls-above-tablea.active:not([href]):not([tabindex]):focus {
	color: white;
}



.datatable-panel .box-header {
	font-weight: 100;
	font-size: 15px;
	margin-bottom: 20px;
}

.datatable-panel .box-header div {
	display: inline;
	margin-right: 5px;
	text-align: left;
}

.panel-blue {
	background-color: #1c55e208;
}

.datatable-panel .box-header .filter-box-actions {
	cursor: pointer;
	color: blue;
	text-transform: uppercase;
	font-size: 10px;
	margin-left: 10px;
	font-weight: bold;
}

.datatable-panel .view-item div {
	display: inline;
}

.datatable-panel .filter-box-save {
	position: absolute;
	right: 0px;
}

.datatable-panel .view-item.selected {
	background-color: #037af8eb;
	color: white;
}

.datatable-panel .view-item.selected .view-actions i {
	color: #ffffff30;
}

.datatable-panel .view-item .view-actions i {
	transition: all 0.3s ease;
	color: #60606078;
	margin-left: 5px;
	font-size: 13px;
	margin-top: 0px;
}

.datatable-panel .view-item .view-actions i:hover {
	color: #037af8;
}

.datatable-panel .view-item.selected .view-actions i:hover {
	color: #ffffff;
}

.datatable-panel .view-item:hover .view-label span {
	font-weight: 500;
}


.datatable-panel .view-item:hover .view-actions {
	display: inline;
}

.datatable-panel .view-item .view-actions {
	/* display:none; */
	float: right;
	padding-right: 3px;
	/* color: red; */
}

.datatable-view-box .box-header {
	margin-bottom: 0px;
}

.datatable-view-box .view-rename input {
	width: 180px;
	display: inline;
	background-color: #ffffffed;
	border-style: dashed;
	border-width: 1px;
	border-color: #037af8;
	padding-top: 3px;
	padding-bottom: 3px;
}

.datatable-view-box .view-item.renaming .view-rename {
	display: inline;
	/* margin-right: 10px; */
}

.datatable-view-box .view-item.renaming .view-icon {
	display: none;
}

.datatable-view-box .view-item.renaming .view-label {
	display: none;
}


.datatable-view-box.datatable-panel .view-rename {
	display: none;
}

.datatable-filter-box .filters {
	display: flex;
	font-size: 12px;
	overflow-x: scroll;
}

.datatable-filter-box .filter-col-head {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 10px;
	color: rgba(90, 99, 126, 0.49);
	margin-bottom: 5px;
}

.datatable-filter-box .filter-col {
	flex-grow: 0;
	flex-basis: 120px;
	margin-right: 10px;
	max-height: 355px;
}

.datatable-filter-box .filter-col .datetime-picker-double-no-time {
	width: 95px;
}


.datatable-filter-box .filter-col-items {
	overflow-y: scroll;
	max-height: 205px;
}

.datatable-filter-box .filter-item {
	background-color: #ffffff;
	border: 1px solid #e9f3ffa8;
	cursor: pointer;
	transition: all 0.3s ease;
	margin-bottom: 3px;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 3px;
	padding-bottom: 3px;
	border-radius: 3px;
	position: relative;
	margin-right: 0px;
}

.datatable-filter-box .filter-item.selected .filter-active {
	display: inherit;
}


.datatable-filter-box .filter-item .filter-item-label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 13px;
}

.datatable-filter-box .filter-item.with-color .filter-item-label {
	margin-left: 15px;
}

.datatable-filter-box .filter-item-color {
	position: absolute;
	font-size: 8px;
	top: 7px;
}

.datatable-filter-box .filter-item .filter-active {
	transition: all 0.3s ease;
	position: absolute;
	display: none;
	right: 5px;
	top: 3px;
	color: #047af8;
}

.datatable-filter-box .filter-item:hover {
	background-color: #047af821;
}

.datatable-filter-box .filter-item:hover .filter-active {
	display: inherit;
	opacity: .3;
}

.datatable-filter-box .filter-item.selected:hover .filter-active {
	opacity: 1;
}

.controls-above-table .filter.active {
	background-color: #047af8;
	border-color: #047af8;
	color: white;
}

.controls-above-table .filter.active span {
	padding-left: 10px;
}

.controls-above-table h3 {
	font-weight: 100;
	color: #0098ff;
	font-size: 24px;
}

.controls-above-table h3 i {
	font-size: 16px;
}

.datatable-wrapper {
	/* position:relative; */
	overflow: visible;
}

.dt-buttons button {
	display: none;
}

table.table tbody tr.dt-rowReorder-moving {
	border: double;
	font-weight: 500;
	background-color: #007bf814;
	border-color: #007bf8;
}

table.table tbody tr.dt-rowReorder-moving td {
	font-weight: 500;
}

.element-header-buttons {
	position: absolute;
	z-index: 1;
	float: right;
	top: 10px;
	right: 10px;
}

.company-header {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	min-height: 300px;
}

.reseller-agent-portal .content-w .content-box {
	margin-top: 0px;
}

.reseller-agent-portal .element-wrapper {
	max-width: 1000px;
	margin: auto;
}

.reseller-agent-portal .element-wrapper .element-box.header {
	padding: 0px;
	overflow: hidden;
}


.company-header .company-logo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.company-header .company-logo .wrapper {
	background-color: white;
	border-radius: 50%;
	margin-top: 100px;
	padding: 15px;
}

.company-header .company-logo .wrapper img {
	height: 100px;
	width: 100px;
	padding: 10px;
}

.reseller-agent-portal {
	margin-top: 40px;
}


.btn.btn-outline-info2 {
	color: #037cf885;
	background-color: transparent;
	background-image: none;
	border-color: #037cf885;
	border-width: 1px;
}

.btn-outline-info2:hover {
	color: white;
	background-color: #037cf8;
}

.form-buttons-w form,
.row-actions form {
	display: inline;
}

.auth-wrapper .vich-image img {
	display: none;
}

.form-group>small {
	padding-bottom: 10px;
	margin-left: 5px;
}

.form-group .textarea-wrapper+small {
	margin-left: 5px;
}


form fieldset label>strong {
	margin-top: 20px;
	display: block;
	border-top: 1px solid #037cf82e;
	min-width: 400px;
	padding-top: 5px;
	color: rgba(0, 0, 0, 0.5);
	text-transform: uppercase;
	font-size: 0.9em;
}

form fieldset:first-of-type {
	margin-top: 0;
}

label.form-check-label span {
	display: inline;
	font-size: .8em;
	/* margin-left:10px; */
}

.form-collection .form-group {
	margin-right: 10px;
	/* min-width: 400px; */
	/* max-width: 200px; */
}

.form-collection legend {
	display: none;
}

.form-collection fieldset>div {
	display: inline;
}

.form-collection fieldset div:last-child a {
	margin-right: 10px;
}

.form-collection fieldset a i {
	padding-top: 10px;
}

.form-collection .form-collection-add {
	margin-left: 10px;
	font-size: 0.81rem;
	margin-bottom: 10px;
}

.form-collection fieldset:first-of-type {
	margin-bottom: 5px;
}

.form-collection fieldset {
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 10px;
}

.form-collection .form-collection-add {
	width: 100px;
}

.form-collection .form-collection-add.btn-wide {
	width: 150px;
}

.form-collection .form-collection-add.btn-x-wide {
	width: 200px;
}

body .row .datetimepicker .datepicker {
	position: fixed;
	margin: auto;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
	z-index: 1000;
	transition: none;
}


.user-avatar img {
	border-radius: 50px;
	display: block;
	width: 28px;
	height: 28px;
	border: 1px solid #047bf83b;
	padding: 2px;
	background-color: white;
	display: inline;
	margin-right: 5px;
	margin-top: -6px;
}

.setup-box {
	border-radius: 0px 0px 4px 4px;
	background-color: #fff;
	-webkit-box-shadow: 0 2px 30px 0 rgba(16, 37, 133, 0.1);
	box-shadow: 0 2px 30px 0 rgba(16, 37, 133, 0.1);
	padding: 20px;
	margin-bottom: 20px;
	-webkit-animation-name: fadeUp;
	animation-name: fadeUp;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

.setup-box ul {
	list-style: none;
	margin-block-start: inherit;
	margin-inline-start: inherit;
	margin-bottom: inherit;
	padding-inline-start: 0px;
	margin-top: 20px;
}

.setup-box li {
	padding: 5px;
	transition: all 0.5s ease;
}

.setup-box li a {
	transition: all 0.5s ease;
	padding: 8px;
}

.setup .element-box {
	overflow: hidden;
}

.setup .element-box i.fal {
	position: absolute;
	bottom: -40%;
	right: -40%;
	font-size: 200px;
	border-radius: 50%;
	background-color: #0598fe0a;
	color: white;
	padding: 50px;
	z-index: 0;
}



.setup-box li:hover {
	background-color: #bfd6fa21;
	padding-left: 10px;
	border-radius: 4px;
}

.setup-box li:hover a {
	color: #047bf8;
}

.setup-box li a i {
	padding-right: 10px;
	color: #047bf8;
	font-size: 1.2em;
	text-align: right;
	width: 29px;
}

/**
	We put this code in here because Mel wanted us to.
**/
.setup-box li a .fa-puzzle-piece {
	padding-left: 2.5px;
}

.setup-box li a .fa-map-marker-check {
	margin-left: -2px;
	margin-right: 2px;
}

.setup-box li a {
	color: inherit;
	padding: 10px;
}

.setup-box.red {
	border-top: 5px solid #e65252;
}

.setup-box.blue {
	border-top: 5px solid #0098ff;
}

.setup-box.green {
	border-top: 5px solid #24b314;
}


.user-avatar.empty img {
	display: none;
}

.user-avatar.empty {
	height: 40px;
	width: 40px;
}

.user-avatar.small img {
	width: 35px;
	height: 35px;
}

.user-avatar.small.empty {
	height: 35px;
	width: 35px;
}


.user-avatar.large img {
	width: 75px;
	height: 75px;
}

.user-avatar.large.empty {
	height: 75px;
	width: 75px;
}


td.avatar {
	white-space: nowrap;
}

.menu-w .progress-bar {
	display: none;
	position: relative;
	padding: 2px;
	position: absolute;
	bottom: 0px;
	width: 100%;
}


.menu-w.busy .progress-bar {
	display: inherit;
}

.menu-w .progress-bar:before {
	/* content:''; */
	border: 1px solid #fff;
	border-radius: 10px;
	position: absolute;
	top: -4px;
	right: -4px;
	bottom: -4px;
	left: -4px;
}

.menu-w .progress-bar .inner-bar {
	position: absolute;
	border-radius: 10px;
	top: 0;
	right: 100%;
	bottom: 0;
	left: 0;
	background: #fff;
	width: 0;
	animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
	0% {
		left: 0%;
		right: 100%;
		width: 0%;
	}

	10% {
		left: 0%;
		right: 75%;
		width: 25%;
	}

	90% {
		right: 0%;
		left: 75%;
		width: 25%;
	}

	100% {
		left: 100%;
		right: 0%;
		width: 0%;
	}
}

.user-avatar.inline {
	display: inline;
}

table td .user-name {
	margin-left: 5px;
	white-space: nowrap;
}

.user-avatar.inline .user-name span {
	margin-left: 35px;
}

/* .hide {display: none !important;}  THIS breaks a lot of UI hiding / showing elements ie: capacity type settings*/
.hide {
	display: none !important;
}

.alert.split {
	display: flex;
}

.alert.split .content {
	flex-grow: 1;
}

.alert.split .alert-btn {
	flex-grow: 0;
	flex-basis: 120px;
	margin-top: 10px;
	text-align: right;
}

.ag-theme-quartz.grid .ag-ltr .row-status-reserved .ag-cell-value {
	font-weight: 400;
}

.user-avatar span {
	margin-left: 10px;
}

.table tbody tr.order-status-draft {
	background-color: #FBF9F9;
}

.table tbody tr.order-status-reserved {
	background-color: #F8FFF8;
}

.table tbody tr.even {
	/* background-color:white; */
}

.table tbody tr.odd {
	background-color: rgba(237, 242, 245, 0.18);
}

.table thead th {
	border-bottom: 2px solid rgba(0, 0, 0, 0.05)
}

.form-desc span {
	color: #e83e8c;
	font-weight: 400;
}


.table.table-padded tbody tr:hover td {
	background-color: #f6f7fa;
	border-top: 1px solid #097cf830;
	border-bottom: 1px solid #097cf830;
	/* border-right: none; */
	/* border-left: none; */
}

.table.table-padded tbody tr:hover td:last-child {
	border-right: 1px solid #097cf830;
}

.table.table-padded tbody tr:hover td:first-child {
	border-left: 1px solid #097cf830;
}


.element-header span.back {
	display: block;
	font-size: 12px;
	font-weight: 100;
	margin-bottom: 10px;
	margin-top: 10px;
}

.element-header span a {
	color: #999;
}

.content-w.orders .content-box {
	padding: 0px;
}

table.dataTable tbody {
	cursor: pointer;
}

table.dataTable thead tr th.private {
	padding-right: 10px;
}

table.dataTable tbody td.private {
	opacity: .5;
}

.ag-root-wrapper .ag-watermark {
	display: none;
}

.ag-overlay-no-rows-wrapper {
	color: rgba(90, 99, 126, .49);
}


.dataTable th.actions {
	opacity: 0;
	transition: opacity .5s;
	width: 25px;
}

.dataTable:hover th.actions {
	opacity: 1;
}


.dataTable td.actions a {
	margin-right: 7px;
}

.dataTable td.actions {
	opacity: 0;
	transition: opacity .5s;
}

.dataTable tr:hover td.actions {
	opacity: 1;
}

.dataTables_wrapper .row:first-child {
	margin-bottom: 0rem;
	padding-bottom: 0rem;
}

.dataTables_wrapper .row:last-child {
	margin-top: 1rem;
}

.user-info {
	color: #969696;
	font-size: 11px;
	line-height: 1;
}


div.dataTables_wrapper div.dataTables_info {
	color: #99999991;
	font-size: .9em;
	padding-top: 10px;
	font-weight: 300;
}

.table td {
	padding: .6em;
	font-size: .95em;
	vertical-align: top;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
	z-index: 100;
	color: #868f99;
	font-weight: 100;
	font-size: 1.5em;
	margin-right: 5px;
}


.select2-container--default .select2-selection--multiple .select2-selection__choice {
	float: inherit;
}


body>.alert {
	color: #285462;
	border-width: 1px;
	background-clip: padding-box;
	border-radius: 8px;
	cursor: pointer;
	padding-right: 20px;
	font-size: 14px;
	padding: 15px;
	padding-left: 15px;
}

body>.alert .close {
	color: white;
	padding-top: 5px;
	/* margin-top: 5px; */
	padding-right: 5px;
	font-size: 28px;
}

body>.alert-warning .close {
	color: #0f0f0f;
}

body>.alert-danger .close {
	color: #3e0000;
}

body>.alert .right {
	margin-right: 10px;
	margin-left: 10px;
}

body>.alert .alert-title {
	font-size: 16px;
	font-weight: 500;
}

body>.alert.alert-info {
	color: #ffffffc4;
	background-color: #0071f3;
	border-color: #047bf85e;
	-webkit-box-shadow: 0 2px 20px 0 #047bf8bd;
	box-shadow: 0 2px 20px 0 #047bf859;
	z-index: 2060;
}

body>.alert.alert-warning {
	background-color: #fcedbe;
	border-color: #fbe6a8;
	-webkit-box-shadow: 0 2px 20px 0 #fbe4a0c9;
	box-shadow: 0 2px 20px 0 #fbe4a0db;
}

body>.alert.alert-danger {
	color: #522626;
	background-color: #ee8989;
	border-color: #e86060;
	-webkit-box-shadow: 0 2px 20px 0 #e65252c4;
	box-shadow: 0 2px 20px 0 #e65252c7;
}

.dataTables-edit {
	color: #3d4b5b;
}

table.table.dataTable th,
table.table td {
	border-top: 1px solid #0073ff17;
	border-bottom: 1px solid #0073ff17;
	/* user-select: none; */
}

table.table tr:last-child td {
	/* border-bottom: inherit; */
}

.table thead {
	color: rgba(90, 99, 126, 0.49);
	letter-spacing: 1px;
}

.table thead th:after,
.table thead th:before {
	bottom: 0.5em !important;
}

.table tfoot th {
	font-size: 0.7rem;
	text-transform: uppercase;
	border-top: none;
}

.table tfoot th {
	border-top: 2px solid rgba(0, 0, 0, 0.05)
}



.dataTables_wrapper {
	-webkit-touch-callout: none;
	/* iOS Safari */
	/* -webkit-user-select: none; */
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	/* user-select: none; */
	/* Non-prefixed version, currently
                                  supported by Chrome and Opera */
	position: relative;
}

.auth-box-w img {
	max-height: 70px;
	margin-top: 30px;
}

.nav-link i {
	font-size: 1em;
	color: #b2b2b2;
}

.expanded-radio-picker .element-box {
	border: 1px solid rgba(0, 0, 0, 0.05);
	cursor: pointer;
	padding: 0px;
	margin-bottom: 0.75em;
	margin-top: 0.75em;
	padding-bottom: 5px;
}

[disabled] .element-box {
	cursor: default;
}

.expanded-radio-picker .element-box.selected {
	border-color: #0073ff2b;
	background-color: #067af80d;
}

.expanded-radio-picker p {
	margin-bottom: 0px;
	color: #636c72 !important;
}

.expanded-radio-picker h5 {
	margin-bottom: 0px;
	font-weight: normal;
	color: #636c72 !important;
	font-size: 1.1em;
}

.expanded-radio-picker input {
	/* opacity: 0; */
	/* position: absolute; */
	/* width: 100%; */
	/* height: 100%; */
}

.expanded-radio-picker .radio-content-wrapper {
	padding: 15px 10px;
	position: relative;
}

.radio-text {
	margin-left: 40px;
}

.expanded-radio-picker .fas {
	float: left;
	font-size: 1em;
	vertical-align: middle;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #636c72 !important;
}

.expanded-radio-picker .form-check {
	padding-left: 15px;
}

.expanded-radio-picker .element-box.selected .fas {
	color: #0073ff !important;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-header h6 {
	color: #3E4B5B;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-header.selected h6 {
	color: #047bf8;
}

.todo-sidebar-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 10px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section,
.todo-sidebar-header {
	border-bottom: none;
}

.todo-app-w .todo-sidebar .todo-sidebar-section {
	padding-left: 5px;
	padding-right: 5px;
}

.todo-sidebar-section-sub-section-toggler {
	padding-top: 7px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section {
	margin-top: 0px;
	padding-top: 0px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-content ul li a.selected {
	color: #047bf8;
}

.todo-sidebar-section-sub-section-content {
	overflow-y: hidden;
	max-height: 0;
	transition-property: all;
	transition-duration: .5s;
}

.todo-sidebar-section-sub-section-content.open {
	max-height: 500px;
}

.team-user .image-editors {
	right: 20px;
	left: inherit;
	bottom: 15px;
}


.image-editors {
	position: absolute;
	bottom: 20px;
	left: 20px;
	transition-duration: 0.5s;
	z-index: 6;
	opacity: .4;
	transition-duration: 0.5s;
}

.image-editors.top-right {
	top: 10px;
	right: 10px;
}

.image-editors:hover {
	opacity: 0.95;
}

.image-editors:hover {
	cursor: pointer;
}

#image_form_gallery .vich-image>a {
	display: none;
}

#image_form_gallery .gallery-photo {
	margin-bottom: 20px;
}

#image_form_gallery .add-photo-wrapper,
#hero-photo .add-photo-wrapper {
	position: relative;
	padding-bottom: 60%;
	background-color: #1a55e205;
	border-radius: 8px;
	cursor: pointer;
}

.form-actions.float {
	position: fixed;
	bottom: 0px;
	box-shadow: -10px -10px 10px -10px #33333314;
	z-index: 100;
	background-color: white;
	padding: 1rem;
	left: 0px;
	padding-right: 40px;
	padding-left: 40px;
}

.table .gift-card-column-image {
	width: 150px;
}

.env-dev .form-actions.float,
.env-stage .form-actions.float {
	height: 66px;
}


.menu-offset-side .form-actions.float {
	width: calc(100% - 222px);
	left: 221px;
}


.image-editors .fas.fa-circle.fa-stack-2x {
	color: rgba(0, 0, 0, 0.55);
}

.image-editors .fa-stack:hover .fas.fa-circle.fa-stack-2x {
	color: rgba(0, 0, 0, 0.2);
}

.image-editors .fa-stack {
	font-size: 1.5em;
	/* background-color: white; */
	/* border-radius: 50%; */
}



#image_form_gallery.hero-wrapper .add-photo-wrapper {
	/* max-height:300px; */
	padding-bottom: 50px;
}

#image_form_gallery .add-photo-button,
#hero-photo .add-photo-button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
	font-size: 14px;
	background-color: rgba(218, 234, 255, 0.11);
	padding: 10px 15px;
	border-radius: 6px;
	white-space: nowrap;
}

#image_form_gallery .col-sm-12 {
	margin-bottom: 15px;
}

#image_form_gallery .add-photo-button:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.1);
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.items-list .far.fa-lock-alt {
	position: relative !important;
	right: 0px;
	top: 5px;
}

.items-list li {
	position: relative;
}


.fa-overhang-left {
	position: absolute;
	margin-left: -27px;
	font-size: 1.2em;
	margin-top: -3px;
}

.todo-sidebar {
	position: relative;
}

.todo-sidebar .show-experience {
	position: absolute;
	top: 0px;
	right: 0px;
	background: linear-gradient(to top right, #f9f9f9 0%, #f9f9f9 50%, #047bf8 50%, #047bf8 100%);
	width: 50px;
	height: 50px;
	padding-top: 8px;
	padding-left: 5px;
	color: white;
	cursor: pointer;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	text-align: right;
	padding-right: 5px;
	padding-top: 5px;
}

.todo-sidebar .show-experience.inactive {
	opacity: 1;
}

.todo-sidebar .show-experience.inactive:hover {
	width: 50px;
	height: 50px;
	font-size: 1em;
	opacity: 0.5;
}

.todo-sidebar .show-experience:hover {
	width: 60px;
	height: 60px;
	font-size: 1.2em;
}

.redactorify:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(120deg, #eaee44, #33d0ff);
	opacity: .7;
	position: absolute;
	top: 0px;
	height: 100px;
	width: 100px;
	z-index: 10000;
}

body .redactor-toolbar {
	z-index: 1;
	background-color: #ffffff;
	border-bottom: 1px solid #1c9bfe38;
}

body legend span {
	z-index: 1;
}

.redactor-box strong {
	font-weight: bolder;
}

.redactor-air a.re-button-icon,
.redactor-toolbar a.re-button-icon {
	font-size: 12px;
}

.redactor-box.redactor-styles-on {
	border-radius: 4px;
	border: 2px solid #dde2ec;
}

.redactor-box.redactor-focus {
	border-color: #047bf847 !important;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-width: 2px;
}

.redactorify {
	font-family: "Font Awesome 5 Pro";
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	color: rgba(0, 0, 0, 0);
	border: none;
	font-weight: 400;
	min-height: 300px;
}

.textarea-wrapper {
	position: relative;
}

.fa-spinner.spinMe {
	-webkit-animation-name: spin;
	-webkit-animation-duration: 4000ms;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-name: spin;
	-moz-animation-duration: 4000ms;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	-ms-animation-name: spin;
	-ms-animation-duration: 4000ms;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;

	animation-name: spin;
	animation-duration: 4000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform: translate(-50%, -50%);
}

.textarea-wrapper .fa-spinner {
	-webkit-animation-name: spin;
	-webkit-animation-duration: 4000ms;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-name: spin;
	-moz-animation-duration: 4000ms;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	-ms-animation-name: spin;
	-ms-animation-duration: 4000ms;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;

	animation-name: spin;
	animation-duration: 4000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 4em;
	opacity: 0.8;
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}

	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.dimmed h5 {
	opacity: 0.4;
}

.dimmed {
	opacity: 0.7;
}

.dimmed-strong {
	opacity: 0.1;
}

#experience-editor-wrapper .experience-summary {
	position: relative;
}

#experience-editor-wrapper .experience-summary h3 {
	position: absolute;
	bottom: 30px;
	left: 30px;
	color: white;
	text-shadow: 2px 2px 11px rgba(0, 0, 0, 0.56);
}

#experience-editor-wrapper .experience-summary p {
	position: absolute;
	bottom: 12px;
	left: 30px;
	color: white;
	text-shadow: 2px 2px 11px rgba(0, 0, 0, 0.56);
}


#experience-left-nav {
	top: 115px;
	padding-top: 10px;
	overflow-y: scroll;
	padding-bottom: 50px;
}

#experience-editor-wrapper {
	padding-top: 80px !important;
}

#experience-sub-nav {
	background-color: #1b55e2;
	position: fixed;
	height: 70px;
	justify-content: end;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: none;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 15px;
	margin-top: 50px;
	z-index: 2;
	width: 100%;
	display: inline-flex;
	top: 0px;
	padding-top: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#experience-left-nav::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#experience-left-nav {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.right-sidebar.open {
	box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), 0 4px 24px 2px rgba(0, 0, 0, .1);
	right: -5px;
}

.right-sidebar {
	position: fixed;
	top: 51px;
	bottom: 0px;
	width: 450px;
	right: -460px;
	background-color: white;
	border-left: 1px solid #eaeaea;
	z-index: 5;
	transition: .5s;
	box-shadow: 3px 3px 3px;
	margin-right: 0px;
	overflow: hidden;
}

.right-sidebar .sidebar-content {
	max-height: 100%;
	overflow-y: scroll;
	margin-top: 20px;
	padding-bottom: 70px;
	bottom: 0px;
}



.right-sidebar .header {
	background-color: #097cf8;
	padding: 15px;
	margin-left: 0px;
	margin-right: 0px;
	width: 100%;
	z-index: 4;
}

.right-sidebar .header:before {
	background: linear-gradient(90deg, rgb(255 255 255 / 19%) 0, rgba(0, 0, 0, 0.2) 100%);
	content: "";
	display: block;
	width: 100%;
	height: 50px;
	position: absolute;
	top: 0px;
	left: 0px;
}

.right-sidebar .header button {
	color: white;
	font-weight: 100;
	font-size: 36px;
	top: 5px;
	position: absolute;
	right: 15px;
}

.right-sidebar .header h4 {
	color: white;
	margin-top: 10px;
	text-shadow: none;
	font-weight: bold;
	font-size: 16px;
	margin: 0px;
}

.experience-day-detail .experience-timeslot-note {
	display: block;
	margin-left: -5px;
	/* height: 20px; */
	/* padding: 0px; */
	/* margin: 0px; */
	max-width: 400px;
}

.experience-timeslot-note .edit-note {
	margin-top: 15px;
}

.experience-timeslot-note .edit-note input {
	display: inline-block;
	max-width: 300px;
}

.experience-timeslot-note a {
	/* background-color: #fbfbfb; */
	display: inline;
	padding: 3px;
	border-radius: 4px;
	padding-left: 5px;
	padding-right: 5px;
	color: inherit;
}

#experience-sub-nav .back {
	margin-top: 5px;
	margin-left: 5px;
	width: 44px;
	border-radius: 8px;
	margin-bottom: 10px;
	transition: all .4s;
}

#experience-sub-nav .back:hover {
	background-color: #a2a2a236;
}

#experience-sub-nav .img-wrapper {
	padding-left: 5px;
	margin-top: 5px;
}

#experience-sub-nav .img-wrapper:hover {
	/* opacity: 0.8; */
	cursor: pointer;
}

#experience-sub-nav .name-wrapper {
	padding-left: 10px;
	flex-grow: 1;
	margin-top: 5px;
}

#experience-sub-nav .name-wrapper .label {
	color: white;
}

#experience-sub-nav .name-wrapper h5 {
	padding-bottom: 0px;
	margin-bottom: 0px;
	font-weight: 400;
	color: #fff;
	margin-top: 0px;
}

#experience-sub-nav .name-wrapper .value-pair {
	opacity: 0.7;
}

#experience-sub-nav .back i {
	color: white;
	font-size: 2.5em;
	/* width: 28px; */
	padding-left: 10px;
	padding-right: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
}

#experience-sub-nav .actions-wrapper {
	margin-right: 20px;
	margin-top: 6px;
	opacity: .9;
	display: flex;
}

#experience-sub-nav .actions-wrapper label {
	color: white;
	padding-right: 10px;
	margin-top: 8px;
}

#experience-sub-nav img {
	height: 40px;
	border-radius: 4px;
	border: 1px solid lightgrey;
	margin-right: 5px;
}

.my-event {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-radius: 2px;
	background-color: #1867c0;
	color: #ffffff;
	border: 1px solid #1867c0;
	width: 100%;
	font-size: 12px;
	padding: 3px;
	cursor: pointer;
	margin-bottom: 1px;
}

.v-calendar-weekly__day-label.primary--text {
	background-color: #1866c0;
	border: none;
	color: white;
}

#vue-timeslot-creator .element-box {
	min-height: 450px;
}

#vue-timeslot-creator .anytime-ecom {
	margin-left: 30px;
	border-left: 1px solid #f2f4f8;
}

#vue-timeslot-creator .row.times {
	margin-left: 15px;
	border-left: 1px solid #f2f4f8;
}

#vue-timeslot-creator .form-check {
	padding-left: 0px;
}

#vue-experience-calendar>div {
	position: relative;
}

#timeslot-creator {
	/* margin-bottom: 20px; */
	/* padding-bottom: 20px; */
	/* position: relative; */
	top: 0px;
	z-index: 1;
	/* background: white; */
	/* width: 100%; */
	/* display: block; */
}

#timeslot-creator .single-daterange-wrapper .fa-calendar {
	display: inline-block;
	position: absolute;
	bottom: 12px;
	top: auto;
}

#timeslot-creator .single-daterange-wrapper {
	position: relative;
	display: inherit;
}

.datepicker.dropdown-menu {
	z-index: 3;
	padding: 10px;
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
	background-image: none;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
	background-color: #047cf8;
}


a {
	color: #0098ff;
}

.alert-danger a {
	color: #005aff;
}

.element-box.chart h6 {
	text-align: center;
	font-size: 1.2rem;
	margin-bottom: 0px;
}

.element-box.chart p {
	text-align: center;
	font-size: 14px;
	font-weight: 100;
	color: #b9b9b9;
}


.single-daterange-wrapper i {
	position: absolute;
	top: 12px;
	padding-left: 10px;
	color: #9e9e9e;
}

.single-daterange-wrapper input {
	padding-left: 28px;
}

.single-daterange-wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
}

.tasks-section .v-btn--fab.v-size--small {
	height: 30px;
	width: 30px;
}

.tasks-section .v-calendar .v-event.v-event-end {
	width: 100%;
}

.add_timeslot:hover {
	cursor: pointer;
}

.fade-enter-active {
	transition: opacity .4s;
}

.fade-leave-active {
	transition: opacity .4s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}


.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}


.list-enter-active,
.list-leave-active {
	transition: all .4s;
}

.list-enter,
.list-leave-to {
	opacity: 0;
}

.todo-content-header {
	display: inline-block;
}

.todo-wrapper {
	padding: 30px;
}

.todo-app-w .todo-sidebar {
	padding: 20px;
	flex: 0 0 225px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section {
	margin-bottom: 12px;
}

#experience-sub-nav .aeh-actions {
	position: relative;
}

.show-steps-remaning:hover #experience-steps-remaining {
	opacity: 1;
	visibility: visible;
	transition: opacity .25s ease-in-out;
	-moz-transition: opacity .25s ease-in-out;
	-webkit-transition: opacity .25s ease-in-out;
}

a.experience-status-toggle {
	line-height: 90px;
}

#experience-steps-remaining {
	position: absolute;
	text-align: left;
	top: 65px;
	right: -2px;
	width: 250px;
	z-index: 100;
	right: 25px;
	opacity: 0;
	visibility: hidden;
	padding-bottom: 0px;
}

#experience-steps-remaining .element-box {
	background-color: #fff;
	padding: 15px;
	color: #495057;
	line-height: 125%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

#experience-steps-remaining .element-box h5 {
	color: #495057;
	padding-top: 0px;
	margin-top: 0px;
}

#experience-steps-remaining ul {
	margin-bottom: 15px;
	padding-bottom: 0px;
	padding-left: 20px;
}

#experience-steps-remaining li>i {
	display: none;
	left: 0px;
	top: 3px;
}

#experience-steps-remaining ul a,
#experience-sub-nav .actions-wrapper #experience-steps-remaining a {
	color: #495057 !important;
}

#experience-steps-remaining ul>li {
	position: relative;
}

#experience-steps-remaining:before {
	position: absolute;
	display: inline-block;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #FFF;
	top: -8px;
	right: 15px;
	content: '';
}

.todo-app-w .todo-content {
	padding: 10px;
}

#add-timeslots-toggle,
#edit-timeslots-toggle {
	margin-left: 15px;
	z-index: 25;
	position: absolute;
	right: 0px;
	top: 30px;
}


.add-on-values li {
	padding: 8px;
	font-size: 12px;
	margin-bottom: 3px;
	padding-bottom: 0px;
	margin-top: 0px;
	padding-top: 0px;
}

.add-on-values {
	padding-inline-start: 25px;
	margin-bottom: 10px;
	margin-top: 0px;
}

.absolute-pos {
	position: absolute;
	width: 100%;
	background: white;
	z-index: 10000;
}

.v-calendar-weekly__day:hover .dayToggle {
	opacity: 0.5;
	transition: opacity 1s ease-out;
	-moz-transition: opacity 1s ease-out;
	-webkit-transition: opacity 1s ease-out;
	-o-transition: opacity 1s ease-out;
	cursor: pointer;
}

.dayToggle {
	-webkit-transition: all 1s;
	/* Safari prior 6.1 */
	transition: all 1s;
	opacity: 0;
	cursor: pointer;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

[type="number"] {
	width: 100%;
	/* override vuetify craziness */
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-header i.far {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: -30px;
	font-size: 15px;
	color: #047bf8;
}

.fa-invisible {
	visibility: hidden;
}

.items-list .fa-check {
	opacity: 0.5;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-content ul li a {
	position: relative;
	width: 100%;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-content ul li a>i {
	position: absolute;
	right: 0px;
	top: 0px;
}

.tasks-section .v-event .fa-edit {
	float: right;
	margin-right: 5px;
	vertical-align: middle;
	line-height: 20px;
}

#scheduling_type_date .tasks-section .v-event .fa-edit {
	line-height: 80px;
}

#scheduling_type_date .tasks-section .v-event {
	height: 80px !important;
	line-height: 80px;
}

.element-box.compact .status-loading,
.element-box.compact .status-empty {
	padding: 2em;
}

.element-box.compact.more {
	font-size: 12px;
	/* color: #afafaf; */
	font-weight: 100;
}

.element-box.compact.more .name {
	text-align: right;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.element-box.compact.more .value {
	color: #040404;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.element-box.compact.more .row:last-child .name,
.element-box.compact.more .row:last-child .value {
	border-bottom: none;
}

.element-box.more {
	overflow: hidden;
}

.element-box.compact {
	padding: 0px;
}

.element-box.compact.status-loading,
.element-box.compact.status-empty {
	background-color: white;
}

.element-box.compact .loader {
	height: 66px;
}

.element-box.compact .loader .progress {
	margin-left: 20px;
	margin-bottom: 30px;
	margin-right: 20px;
	margin-top: 20px;
}


.field-value {
	cursor: pointer;
	display: inline;
	padding-right: 20px;
}

.form-collection fieldset {
	position: relative;
}

.form-collection {
	display: inline-grid;
}

.form-collection .form-group input[type="text"] {
	min-width: 300px;
}

.form-collection .form-group input[type="text"].max-w-175 {
	max-width: 175px;
	min-width: auto;
}

.form-collection .form-group input[type="text"].makeSkinny {
	min-width: auto;
}

.custom_field_form_custom_field_values-collection-actions {
	position: absolute;
	top: 8px;
}

.form-collection .form-group {
	display: inline-block;
	vertical-align: top;
}

.form-collection .form-check {
	padding-left: 5px;
}


.element-box:hover {
	box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.32);
}


.element-box.add-on .description {
	padding-bottom: 10px;
}

button.popover-button {
	color: inherit;
	background-color: inherit;
	border-color: transparent;
	border-width: 0px;
	padding-top: 1px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 1px;
	font-size: .6em;
}


input.form-control.percent {
	max-width: 80px;
}

.form-collection .form-group input.money,
.form-collection .form-group input.percent {
	min-width: inherit;
	max-width: 100px;
}

.btn:focus,
.all-wrapper .fc-button:focus,
.btn.focus,
.all-wrapper .focus.fc-button {
	outline-width: 3px;
	outline-color: #dde2ec;
	outline-style: solid;
	outline-width: 2px;
}

.btn-group button.active {
	background-color: #1583f8;
	color: white;
}

body .dropdown-menu {
	padding-top: 0px;
	padding-bottom: 0px;
	cursor: pointer;
	font-size: 14px;
	box-shadow: 0 0 0 1px rgba(42, 47, 69, .1), 0 2px 5px 0 rgba(42, 47, 69, .08), 0 1px 1.5px 0 rgba(0, 0, 0, .07), 0 1px 2px 0 rgba(0, 0, 0, .08), 0 0 0 0 transparent;
	transition: box-shadow .08s ease-in, color .08s ease-in;
	border: 2px solid transparent;
	background-color: #ffffff;
	min-width: 300px;
}

.dropdown-menu .dropdown-item:hover {
	background-color: #f8faffc2;
}

.highlighter-red {
	color: #e83e8c;
}

button.popover-button:hover {
	background-color: #bfd6fa21;
	color: black;
}

.popover .values {
	width: 100%;
	display: block;
}

.popover .label {
	flex-grow: 1;
	color: #919295;
	margin-left: 10px;
	font-size: 12px;
}

.popover .value-row .value {
	width: 100px;
	text-align: right;
	margin-right: 10px;
	font-size: 12px;
}

.popover .value-row {
	display: flex;
}

.popover-header {
	white-space: nowrap;
	font-weight: 100;
	font-size: 14px;
}

.popover-body>div {
	/* font-size: 14px; */
	/* display: flex; */
}

#closeInstallPromt {
	position: absolute;
	top: 11px;
	right: 15px;
	color: white;
	font-size: 1.5em;
}

.element-box-item.sub {
	color: #919295;
	font-size: .80em;
}

.popover {
	max-width: 350px;
}

.aeh-actions.show-steps-remaning {
	position: relative;
}


.code-box {
	width: 100%;
	border-radius: 8px;
	background-color: black;
	overflow: hidden;
	margin-bottom: 30px;
	margin-top: 30px;
}

.code-box .header {
	display: flex;
	background-color: #272727;
	padding: 3px;
	font-size: 11px;
	display: flex;
	padding-left: 15px;
}

.code-box .header .title {
	flex-grow: 1;
	padding-top: 5px;
}

.code-box .header .action {
	flex-grow: 0;
	flex-basis: 100px;
	text-align: right;
}

.code-box .content {
	padding: 15px;
	min-height: 50px;
}

.btn.btn-none {
	color: inherit;
	background-color: transparent;
	font-size: inherit;
	outline-style: none;
}

.restriction-table {
	border: 1px solid red;
	border: 1px solid #dde2ec;
	border-radius: 4px;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 20px;
}

.restriction-body input {
	max-width: 50px;
	display: inline;
}

.restriction-body .col-sm-12 {
	display: flex;
}

.restriction-body .form-group {
	margin-bottom: 0px;
}

.restriction-body .restriction-label {
	flex-grow: 1;
	border-bottom: 1px solid #dde2ec;
	padding-top: 10px;
	padding-bottom: 10px;
}

.restriction-body .restriction-size {
	flex-grow: 1;
	border-bottom: 1px solid #dde2ec;
	padding-top: 10px;
	padding-bottom: 10px;
}

.restriction-body .restriction-close {
	flex-grow: 1;
	border-bottom: 1px solid #dde2ec;
	padding-top: 10px;
	padding-bottom: 10px;
}

.restriction-body .restriction-call {
	flex-grow: 1;
	text-align: center;
	border-bottom: 1px solid #dde2ec;
	padding-top: 10px;
	padding-bottom: 10px;
}

.restriction-head {
	font-size: 12px;
}

.restriction-head>div {
	text-align: center;
	vertical-align: middle;
	background-color: #f6f7fa;
}

.restriction-head .row .col-sm-3 {
	min-height: 70px;
}

.restriction-head .row div div {
	position: absolute;
	bottom: 5px;
	text-align: center;
	width: 100%;
}

#restriction_form_empty_min,
#restriction_form_empty_max,
#restriction_form_not_empty_min,
#restriction_form_not_empty_max {
	margin-left: 10px;
}

.restriction-close .form-group {
	display: inline;
}

.nav .nav-link span.count {
	background-color: #dee2e6;
	color: #fff;
	border-radius: 5px;
	font-weight: 500;
	font-size: 0.5rem;
	text-align: center;
	display: inline;
	padding-left: 5px;
	padding-right: 3px;
	padding-top: 2px;
	padding-bottom: 2px;
	/* border: 1px solid #dadde0; */
}

.nav .nav-link.active span.count {
	background-color: #047af8;
}


.user-profile .up-head-w .up-social a {
	margin-left: 5px;
}

.user-profile .up-sub-1 {
	display: block;
	margin-left: 0px;
	padding-left: 0px;
	text-align: left;
	opacity: .8;
	font-weight: 100;
	font-size: 12px;
}


.note-message-content.editing {
	width: 90%;
}

.conversation-index textarea:focus {
	border-color: #dde2ec61;
	background-color: #ffffffa3;
}

.full-chat-w .chat-content-w .chat-message.self .chat-message-content.editing {
	width: 90%;
}

.modal-header span,
.modal-header strong,
.modal-header .avatar {
	vertical-align: inherit;
}

@media (max-width: 1400px) {
	.full-chat-w .chat-content-w .chat-message .chat-message-content {
		max-width: inherit;
	}
}

.conversation-index textarea {
	border-color: transparent;
	margin-top: 5px;
	min-height: 20px;
	margin-bottom: 10px;
	margin-top: 10px;
	background-color: #ffffffa3;
}







.filepond--drop-label.filepond--drop-label label {
	font-size: 14px;
}

.hero-wrapper {
	max-width: 1500px;
}

.content-r-w {
	margin-left: 320px;
}

.modal .content-r-w {
	margin-left: 320px;
}

.content-l-w {
	margin-right: 320px;
}

.modal .content-l-w {
	margin-right: 325px;
}

.modal .platform-modal {
	background-color: #f2f4f8;
	min-height: 95vh;
}


	
.multiselect__tags-wrap .multiselect__tag {
	background-color: #f0f0f0;
	margin-bottom: 0px;
	border:1px solid #3c3c3c42;
	color:#333;
}

.multiselect__tags-wrap .multiselect__tag-icon:after {
	color: #3c3c3c61;
	font-size:18px;
}

.multiselect__tags-wrap .multiselect__tag-icon:focus, .multiselect__tags-wrap .multiselect__tag-icon:hover {
	background-color: #c0c0c0;
}

.multiselect__content .multiselect__option--selected.multiselect__option--highlight {
	background-color: #dedede;
	color:black;
}

.multiselect__content .multiselect__option--highlight {
	background-color: #dedede;
	color:black;
}

.user-profile .buttons {
	text-align: center;
	margin-bottom: 10px;
}

body .maz-base-component {
	font: inherit;
}

body .maz-base-component .maz-border-color {
	border-color: #d1e4fe;
	border-width: 1px;
}

body .maz-base-component .maz-border-radius {
	border-radius: 6px;
}

body.review:before {
	background: -webkit-gradient(linear, left top, right bottom, from(#D7BBEA), to(#65A8F1))
}

body .maz-phone-number-input .maz-input.input-phone-number:not(.has-border-radius) {
	border: 1px solid #dde2ec;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

body .maz-input.is-valid,
.maz-input.is-focused.is-valid {
	border-color: #dde2ec;
}

.maz-phone-number-input .maz-input.input-phone-number .maz-input__input {
	width: 100%;
	font-size: 0.9rem;
	line-height: 1.0;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: none;
	border-radius: 4px;
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	/* height: 2.2rem; */
	/* min-height: inherit; */
}

body .maz-input.is-valid .maz-input__input,
.maz-input.is-valid .maz-input__input:hover,
.maz-input.is-valid .maz-input__input:focus {
	border-color: #dde2ec !important;
}

body .maz-phone-number-input .country-selector {
	width: inherit;
	min-width: inherit;
	max-width: 8rem;
}

body .maz-phone-number-input.no-flags .country-selector {
	max-width: 5.5rem;
	width: inherit;
	min-width: inherit;
}


.country-selector .maz-input.sm.has-value:not(.has-no-label) .maz-input__input {
	width: inherit;
}

.maz-base-component .maz-input.sm input.maz-input__input {
	font-size: 12px;
}

.body .maz-input button.--clear {
	/* display:none; */
}

.maz-input.sm.has-value:not(.has-no-label) .maz-input__input {
	padding-top: 0px;
}

.booking .maz-phone-number-input.sm .maz-input.input-phone-number .maz-input__input {
	padding-right: inherit;
}

#audit-log .audit-header {
	border-bottom: 1px solid #e4e4e4;
	text-transform: uppercase;
	display: flex;
	font-size: 11px;
	padding-bottom: 10px;
	color: #d2d2d2;
	font-weight: 100;
	padding-top: 5px;
}

.audit-log-box .title {
	font-weight: 500;
	font-size: 13px;
}

.audit-log-box-w.action-type-2 .title {
	margin-bottom:0;
	padding-right: 10px;
}

.audit-log-box-w.action-type-2 .audit-log-box {
	flex:none;
}

.audit-log-box.field-change {
	margin-left: 0px;
	margin-right: 0px;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: background-color 0.5s ease;
	font-weight: 100;
	font-size: 12px;
	color: #606060;
	cursor: pointer;
}

.audit-log-box.field-change .audit-content {
	display: flex;
}

.audit-log-box.field-change .audit-date {
	flex-grow: 0;
	flex-basis: 190px;
	margin-left: 10px;
	font-size: 10px;
	min-width: 190px;
}

.audit-log-box.field-change .audit-owner {
	flex-grow: 0;
	flex-basis: 120px;
}

.audit-log-box.field-change .audit-description {
	flex-grow: 1;
	font-size: 14px;
}

.audit-log-box.field-change .audit-icon {
	flex-grow: 0;
	flex-basis: 50px;
	text-align: center;
	min-width: 50px;
}

.audit-log-box.field-change .audit-icon i {
	color: #b5b5b5;
	transition: color 0.5s ease;
	font-size: 18px;
	margin-top: 5px;
}

.audit-log-box.field-change .badge {
	margin-top: 2px;
	margin-bottom: 2px;
	white-space: inherit;
	text-align: left;
	max-width: 250px;
	/* vertical-align: top; */
}

.audit-log-box.field-change .audit-date .name {
	margin-top: 3px;
	margin-left: 40px;
}

.audit-log-box.field-change .audit-date .dates {
	margin-left: 40px;
}

.audit-log-box.field-change .audit-date .address {
	margin-left: 40px;
}

.audit-log-box.field-change .audit-date .dates span {
	margin-left: 0px;
}



.audit-log-box-w.field-change .audit-actions {
	flex-grow: 0;
	flex-basis: 120px;
}



.audit-detail .badge {
	margin-top: 2px;
	margin-bottom: 2px;
	white-space: inherit;
	text-align: left;
	max-width: 250px;
}


.row.hover {
	transition: 0.5s ease;
	cursor: pointer;
}

.row.hover:hover {
	background-color: #f8faffc2;
}

#audit-log .audit-ago {
	font-size: .9em;
	color: #bdbdbd;
}

#audit-log td.audit-detail {
	font-size: 14px;
	color: #989898;
}

.audit-log-box-w .field-change .field {
	text-transform: uppercase;
}

.audit-log-box-w.row.action-type-2 .audit-log-box {
	background-color: #ff00000d;
}


.sm .maz-input__icon.right {
	right: 3px;
}

.payment-add .element-wrapper {
	max-width: 700px;
	margin: auto;
}

.payment-add .element-wrapper .element-box {
	padding: 0px;
	overflow: hidden;
}

.maz-input.has-value:not(.has-no-label) .maz-input__input,
.maz-input.has-hint:not(.has-no-label) .maz-input__input {
	font-size: 0.9rem;
	color: #495057;
	border-color: #dde2ec;
}

body .maz-input.is-valid .maz-input__input,
body .maz-input.is-valid .maz-input__input:hover,
body .maz-input.is-valid .maz-input__input:focus {
	border-color: #dde2ec !important;
}

body .maz-input.is-valid .maz-input__icon path.arrow {
	fill: #DEDEDE;
}

body .maz-phone-number-input .maz-input.is-valid .maz-input__label,
body .maz-input.is-focused.maz-input--primary .maz-input__label,
body .maz-input.has-value .maz-input__label {
	color: #b9b9b9 !important;
	font-size: 9px;
	font-weight: 100;
}

body .maz-phone-number-input .maz-input.input-phone-number:not(.has-border-radius) .maz-input__input:disabled {
	background-color: #e9ecef;
}

.user-profile .button {
	display: inline-block;
	font-size: 11px;
	width: 45px;
}

.user-profile .button button.btn-danger {
	background-color: #e652521f;
	border-color: #e6525212;
}

.user-profile .button button.btn-danger:disabled {
	color: #d6d6d6;
	background-color: #e652520f;
}

.user-profile .button button.btn-success {
	background-color: #5be6521f;
	border-color: #5be6521f;
	color: black;
}

.user-profile .up-main-info .up-sub-header {
	font-weight: 100;
	cursor: pointer;
}

.user-profile.compact .up-header {
	margin-bottom: 5px;
}

.platform-header {
	display: none;
}

.modal .trip .platform-header {
	background-color: #1b55e2;
}

.modal .customer .platform-header {
	background-color: #951be2;
}

.modal .platform-header {
	display: inherit;
	width: 100%;
	padding: 5px;
	padding-left: 10px;
}

.modal .platform-header .title {
	color: #ffffff4f;
	display: block;
	/* font-weight: bold; */
	font-size: 20px;
	text-transform: uppercase;
	max-height: 30px;
}

.modal .platform-header .subtitle {
	color: white;
	display: block;
}


.user-profile .button button {
	display: block;
	box-shadow: inherit;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	margin: auto;
	margin-bottom: 5px;
	padding-top: 4px;
	border: 1px solid #e4e4e4;
	background-color: #0098ff05;
	cursor: pointer;
}

.user-profile .button button.btn-primary {
	border-color: #047bf8;
	background-color: #047bf8;
}

.user-profile .button button.btn-yellow {
	border-color: #f8da04;
	background-color: #f8da04cc;
	color: white;
	padding-bottom: 2px;
}

i.yellow {
	color: #f8da04cc;
}

.user-profile .button button:disabled {
	color: #d4d4d4;
	background-color: #0098ff05;
	border: 1px solid #e4e4e4a8;
}

#book-button-preview {
	border-radius: 4px;
	display: inline-block;
	width: 450px;
	text-align: left;
}

#widget-preview {
	border-radius: 4px;
	border: 1px solid lightgrey;
	display: inline-block;
	width: 800px;
	min-height: 300px;
}

#calendar-widget-preview {
	border-radius: 4px;

	width: 400px;
	height: 470px;
	overflow: hidden;
	padding-bottom: 0px;
	margin-bottom: 0px;
	padding: 20px;
	border: 1px solid #e6e8eb;
	background-color: #f2f4f8;
}

.review .auth-box-w form .form-group label {
	color: inherit;
}

#calendar-widget-preview iframe {
	-ms-zoom: 0.75;
	-moz-transform: scale(0.75);
	-moz-transform-origin: 0 0;
	-o-transform: scale(0.75);
	-o-transform-origin: 0 0;
}

#calendar-widget-preview iframe {
	border-radius: 4px;
	margin: 0px;
	padding: 0px;
	min-height: 300px;
	height: 450px;
}

#tripworks-modal-iframe {
	border: 0px;
}

.tip-box {
	width: 525px;
	height: 300px;
}

.tip-box .avatar .img-thumbnail {
	width: 150px;
}

.tip-box .avatar {
	display: inline-block;
}

.tip-amounts .input-group {
	flex-basis: 150px;
	flex-grow: 0;
	margin-right: 10px;
}


.booking-field-table {
	margin-top: 30px;
	border: 1px solid #dde2ec;
	border-radius: 4px;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 30px;
}

.booking-field-body input {
	max-width: 50px;
	display: inline;
	text-align: center;
}

.booking-field-body .col-sm-12 {
	display: flex;
}

.booking-field-body .form-group {
	margin-bottom: 0px;
}

.booking-field-body .field-name {
	flex-grow: 1;
	border-bottom: 1px solid #dde2ec;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 8px;
}

.booking-field-body .field-online {
	flex-grow: 1;
	border-bottom: 1px solid #dde2ec;
	padding-top: 10px;
	padding-bottom: 10px;
}

.booking-field-body .field-offline {
	flex-grow: 1;
	border-bottom: 1px solid #dde2ec;
	padding-top: 10px;
	padding-bottom: 10px;
}

.booking-field-head {
	font-size: 12px;
}

.booking-field-head>div {
	text-align: center;
	vertical-align: middle;
	background-color: #f6f7fa;
}

.booking-field-head .row>div {
	min-height: 70px;
	position: relative;
}

.booking-field-head .row div div {
	position: absolute;
	bottom: 5px;
	text-align: center;
	width: 100%;
}


pre {
	overflow-x: auto;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
	padding: 15px;
	background-color: whitesmoke;
	border-radius: 8px;
}

.required-warning {
	width: 100%;
	display: block;
}

.mobile-only {
	display: none !important;
}

.construction-stage-bar {
	background-image: url(https://cdn-images.tripworks.com/images/construction-head-stripe.png);
	height: 5px;
	content: "";
	z-index: 10;
	width: 100%;
	background-repeat: repeat;
	position: fixed;
	top: 0px;
}

.quick-filter {
    position:relative;
    z-index: 1;
    margin-right:10px;
}

.quick-filter input {
    background-color: rgba(121, 138, 185, 0.07);
    border-radius: 30px;
    padding: 8px 15px 8px 48px;
    display: block;
    width: 220px;
    outline: none;
    border: none;

}

.quick-filter input:focus {
    background-color: rgba(121, 138, 185, 0.07);
    border: 1px solid #e6e6e6;
}

.quick-filter:before {
    font-family: "Font Awesome 5 Pro" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 16px;
    content: "\f002";
    color: rgba(0, 0, 0, 0.4);
}



/*  Mobile Portrait */
@media (max-width: 768px) {
	.visible-mobile {
		display: inherit !important;
	}

	.hidden-mobile {
		display: none !important;
	}

	.mobile-only {
		display: inherit !important;
	}

	#hubspot-messages-iframe-container[style*="height: 96px"] {
		margin-bottom: 65px;
	}

	body.review:before {
		background: white;
	}

	body.review .auth-box-w {
		border-radius: 0px;
		box-shadow: none;
	}

	.content-box .side-nav {
		width: inherit;
		position: inherit;
		padding-bottom: 0px;
	}

	.content-box .content-r-w {
		margin-left: inherit;
	}

	.company-header {
		min-height: 150px;
		/* margin-bottom: 10px; */
	}

	.company-header .company-logo .wrapper {
		margin-top: 40px;
	}

	.company-header .company-logo .wrapper img {
		height: 60px;
		width: 60px;
	}

}

/*
@media (min-width: 890px) {
	.modal-dialog.modal-lg {
		max-width: 800px;
	}
}
 */

@media (min-width: 700px) {
	.modal-dialog.modal-lg {
		max-width: 95%;
	}

	.modal-dialog.modal-xl {
		max-width: 95%;
	}

}


/*
@media (min-width: 1090px) {
	.modal-dialog.modal-lg {
		max-width: 1000px;
	}
}
 */

@media (min-width: 1100px) {
	.modal-dialog.modal-lg {
		max-width: 1100px;
	}

	.modal-dialog.modal-xl {
		max-width: 1300px;
	}

}

.form-collection .text-danger.required-warning {
	/* position: absolute; */
}


.text-sentiment-negative {
	background: #ff000017;
	padding-left: 2px;
	padding-right: 2px;
}

.text-sentiment-competitor {
	background: #31d4d733;
	padding-left: 2px;
	padding-right: 2px;
}


#beamerSelector {
	display: none;
}

.text-sentiment-positive {
	background: #00c33329;
	padding-left: 2px;
	padding-right: 2px;
}

.clear {
	clear: both;
}



/* -- Integration Panel's Styling - Block Starts  -- */

.integrationBoxCustom .integration-header-text {
	display: inline-block;
}

.integrationBoxCustom .integration-header-icon {
	float: right;
}

.crm-message-preview-iframe {
	border: none;
	width: 550px;
	height: 750px;
}

/* -- Integration Panel's Styling - Block Starts  -- */
.stripe-integration-logo {
	background-color: #f1f1f1;
}

.grid-empty {
	position: absolute;
	top: 20%;
	left: 0px;
	right: 0px;
	/* bottom: 200px; */
	/* vertical-align: middle; */
	display: flex;
	width: 80%;
	margin: auto;
}

img.empty-icon {
	max-width: 200px;
}

.message-notifications .sidebar-nav {
	border-bottom: 2px solid rgba(0, 0, 0, 0.05);
	padding: 10px;
	text-align: center;
	padding-bottom: 6px;
	padding-top: 3px;
}

input.form-control::placeholder {
	color: #CCCCCC;
}

.ag-row.row-status-archived .ag-cell-value {
	opacity:.5;
}

.ag-row.row-status-draft .ag-cell-value {
	color: #afafaf;
}

.ag-row.row-status-deleted .ag-cell-value {
	color: #ff000073;
}


.news.item .oembed-responsive iframe {
	width: 100%;
	height: auto;
}

.message-notifications .sidebar-nav .badge {
	background-color: rgb(255, 90, 95);
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 8px;
}

.message-notifications .read-indicator.unread {
	left: -12px;
	display: block;
	position: absolute;
	color: #2359c1a8;
	top: 10px;
}

.message-notifications .sidebar-nav a.active {
	font-weight: bold;
	border-bottom: 4px solid #047bf8;
	text-decoration: none;
}

.message-notifications .clear-all {
	text-align: right;
	font-size: 14px;
	cursor: pointer;
}

.message-notifications .severity-1 {
	background-color: #dff0d82b;
}

.message-notifications .severity-2 {
	background-color: #fcf8e3;
}

.message-notifications .severity-3 {
	background-color: #f2dede;
}

.message-notifications blockquote {
	font-size: 12px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 5px;
	padding-bottom: 0px;
	border-left: 5px solid #eee;
	margin-top: 5px;
	padding-left: 10px;
	margin-left: 5px;
}

.message-notifications .sidebar-nav a {
	display: inline;
	font-size: 12px;
	margin-left: 10px;
	margin-right: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
}

.message-notifications .sidebar-content {
	overflow: scroll;
	position: absolute;
	top: 70px;
	bottom: 0px;
	width: 450px;
	padding-left: 10px;
	padding-right: 5px;
	z-index: 10025;
	max-width: 100%;
}

.message-notifications .sidebar-nav .badge {
	margin-left: 5px;
}

.message-notifications .sidebar-content>div {
	width: 95%;
	position: absolute;
	top: 10px;
	display: none;
	/* width: 425px; */
	/* background: red; */
	/* max-width: 425px; */
}

.message-notifications .sidebar-content>div.shown {
	display: block;
}

#right-sidebar-notifications .media.item {
	margin-left: 10px;
	margin-right: 10px;
}

.message-notifications>i {
	cursor: pointer;
}

.message-notifications .item {
	padding: 8px;
	border: 1px solid transparent;
	margin-top: 5px;
	border-radius: 4px;
	position: relative;
	overflow: inherit;
	padding: 5px;
	padding-left: 15px;
	padding-right: 5px;
	transition: 0.5s ease-out;
}

.message-notifications .read-indicator {
	display: none;
	float: left;
	font-size: 7px;
}

.message-notifications .order-owner {
	float: left;
	margin-right: 10px;
}

.message-notifications img.order-owner {
	height: 36px;
	width: 36px;
	padding: 1px;
	border-radius: 50%;
	background-color: white;
	border: 1px solid #ddd;
}

.filepond--credits {
	display: none !important;
}

.message-notifications .item:hover {
	background-color: #fbfcff;
	border: 1px solid #efefef69;
	border-radius: 4px;
}

.message-notifications .item p {
	margin-bottom: 0px;
	font-size: 14px;
	color: #969696;
	padding-bottom: 0px;
}


.review-box-w {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.8rem 0px;
	position: relative;
	max-width: 600px;
}

.review-boxes .review-box-w:first-child:before {
	top: 50%;
}

.review-box-w:before {
	left: 65px;
	content: "";
	display: block;
	width: 3px;
	background-color: #c0cadd;
	top: 0px;
	bottom: 0px;
	left: 75px;
	position: absolute;
}

.review-box:hover {
	border: 1px solid #007bf838;
	background-color: #007bf80a;
}

.review-box {
	transition-duration: .5s;
	cursor: pointer;
	padding: 0.8rem;
	display: -ms-flexbox;
	-ms-flex: 1;
	/* flex: 1; */
	position: relative;
	font-size: 12px;
	-ms-flex-align: center;
	/* align-items: center; */
	border-radius: 6px;
	background-color: #fff;
	-webkit-box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
	box-shadow: 0px 2px 4px rgb(126 142 177 / 12%);
	/* max-height: 100px; */
	border: 1px solid #c0cadd8c;
}

.review-time {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 90px;
	flex: 0 0 90px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100px;
	flex: 0 0 100px;
	font-size: 0.63rem;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.4);
	text-align: right;
	padding-right: 40px;

}

.review-box .pt-btn {
	position: absolute;
	right: 15px;
	top: 15px;
}

.review-box .text-content {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	margin-bottom: 3px;
}

.review-box-w:after {
	left: 70px;
	content: "";
	display: block;
	width: 20px;
	background-color: #c0cadd;
	top: 50%;
	height: 1px;
	left: 80px;
	position: absolute;
	z-index: 1;
}

.review-box-w:last-child:before {
	bottom: 50%;
}

.review-box:before {
	position: absolute;
	top: 50%;
	left: -30px;
	content: "";
	width: 12px;
	height: 12px;
	border: 2px solid #60769f;
	background-color: #f2f4f8;
	border-radius: 20px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 2;
}

.team-user .alert-item:hover {
	background-color: #f3f4f845;
}

.team-user .alert-item {
	display: flex;
	margin-left: 10px;
	/* max-width: 500px; */
	cursor: pointer;
	padding-bottom: 3px;
	padding-top: 3px;
	padding-left: 5px;
	padding-right: 5px;
}

.team-user .alert-item .title {
	flex-grow: 0;
	font-size: 12px;
	flex-basis: 400px;
}

.team-user .alert-item .email {
	flex-grow: 0;
	flex-basis: 70px;
	text-align: center;
	text-transform: uppercase;
}

.team-user .alert-item.header {
	font-size: 10px;
	text-align: center;
}


.team-user .alert-item-section {
	margin-top: 10px;
}

.team-user .alert-item .app {
	flex-grow: 0;
	flex-basis: 70px;
	text-align: center;
	text-transform: uppercase;
}

.team-user .alert-item .sms {
	flex-grow: 0;
	flex-basis: 70px;
	text-align: center;
	text-transform: uppercase;
}


#prospect_form_contacts input {
	min-width: 200px;
}

#review-log .section i {
	position: absolute;
	left: -25px;
	top: 33px;
}

.message-preview .message-header label {
	width: 100px;
	font-size: .65rem;
	text-transform: uppercase;
	text-align: right;
	margin-right: 5px;
}

.message-preview .message-header {
	border-bottom: 1px solid #ebecfe;
	padding-bottom: 10px;
}





.chrome-message {
	box-sizing: border-box;
	width: fit-content;
	max-width: 800px;
	padding-bottom: 1px;
	border-radius: 3px;
	box-shadow: inset 0 0 0 1px #ebebeb, 0 0 5px 1px rgba(0, 0, 0, .1);
	margin-left: 15px;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 40px;
}

.chrome-message .chrome-controls {
	border-radius: 3px 3px 0 0;
	padding: 7px 10px !important;
	background: linear-gradient(#f0f0f0, #e9e9e9);
	border: 1px solid #ebebeb;
}

.chrome-message .chrome-controls ul {
	margin: 0 !important;
	padding: 0 !important;
}

.chrome-message .chrome-controls li {
	border-radius: 50%;
	height: 10px !important;
	width: 10px !important;
	display: inline-block !important;
	margin-right: 6px !important;
}

.chrome-message-editor .receipt-notes {
	margin-left: 40px;
	margin-right: 40px;
	color: #545454;
	font-weight: 100;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 12px;
	color: #cccccc;
}

.waiver-actions .card {
	border: 1px solid #d8d8d8;
	margin-bottom: 10px;
	border-radius: 8px;
	padding: 10px;
	padding-left: 30px;
	padding-right: 30px;
}



.chrome-message .crm-message-body .crm-merged-text {
	padding: 20px;
}

.chrome-message .chrome-controls li.chrome-control-close {
	background: #fd3a57;
}

.chrome-message .chrome-controls li.chrome-control-minimize {
	background: #ffc109;
}

.chrome-message .chrome-controls li.chrome-control-maximize {
	background: #0ed439;
}

.chrome-meta {
	display: flex;
	padding: 5px;
	padding-left: 14px;
	padding-right: 14px;
	font-size: 12px;
	color: #00000061;
}

.chrome-meta .date {
	text-align: right;
	flex-basis: 50%;
}

.chrome-meta .from {
	flex-basis: 50%;
}

.chrome-message-editor {
	padding-left: 1px;
	padding-right: 1px;
}


.vs--searchable .vs__dropdown-toggle,
.vs__search,
.vs__search:focus {
	cursor: pointer;
}

.chrome-message-editor .textarea-wrapper {
	padding: 1px;
}

.chrome-message-editor .redactor-box.redactor-styles-on {
	border-color: white;
}


.input-wrapper .redactor-toolbar-wrapper {
	height: 0px;
	opacity: 0;
	transition-duration: .5s;
}

.input-wrapper .redactor-focus .redactor-toolbar-wrapper {
	height: inherit;
	opacity: inherit;
}


.chrome-subject.input-wrapper .redactor-styles {
	padding: 0px;
}

.chrome-subject.input-wrapper .redactor-box {
	border: none;
	height: 20px;
	transition-duration: .5s;
}

.modal-dialog .chrome-subject {
	padding-left: 15px;
	padding-top: 3px;
	padding-bottom: 3px;
}

.chrome-subject {
	padding: 1px;
	border-bottom: 1px solid #ededed;
	border-top: 1px solid #ededed;
}

.chrome-subject.input-wrapper {
	overflow: hidden;
	padding-left: 15px;
	font-size: 12px;
	color: #00000061;
	padding-top: 5px;
	padding-bottom: 5px;
}

.chrome-subject.input-wrapper p {
	color: #00000061
}

.chrome-subject.input-wrapper .redactor-focus .redactor-toolbar-wrapper {
	height: inherit;
	opacity: inherit;
	margin-bottom: 10px;
}

.chrome-subject.input-wrapper .redactor-focus {
	height: inherit;
	font-size: 14px;
}

.chrome-subject.input-wrapper .redactor-focus,
.chrome-subject.input-wrapper .redactor-focus p {
	color: black;
}

.side-nav {
	width: 310px;
	height: 100%;
	position: fixed;
	overflow-y: scroll;
	top: 75px;
	padding-bottom: 75px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.side-nav::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal .side-nav.left::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modal .side-nav.left {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.modal .side-nav.left {
	top: 10px;
	/* position: absolute; */
	/* top: 0px; */
}

.side-nav.right {
	top: 170px;
	right: 40px;
}

.modal .side-nav.right {
	top: 120px;
	right: 25px;
}


.side-nav .up-contents .item {
	padding-left: 0px;
	padding-right: 0px;
}

.side-nav .item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	/* margin-bottom: 5px; */
	font-size: .9rem;
	margin-top: 3px;
	margin-bottom: 3px;
	padding-left: 20px;
	padding-right: 15px;
	width: 100%;
}

.side-nav .item a {
	/* flex-basis: 30px; */
}

.side-nav .item.status-editing {
	flex-wrap: wrap;
}

.side-nav .item.status-editing .field {
	width: 100%;
	margin-top: 5px;
}

.side-nav .item.status-editing .field .actions {
	margin-bottom: 15px;
}


.side-nav .editor .item {
	display: -ms-flexbox;
	-ms-flex-pack: justify;
	font-size: .72rem;
	flex-wrap: wrap;
}

.side-nav .editor .actions {
	padding-left: 25px;
	padding-top: 5px;
}

.side-nav .labels a span.label:hover,
.trip-contact .custom-field-values .field-row .booking-edit-custom-field-value:hover,
.trip-contact .custom-field-values .field-row .field-label:hover {
	color: #0098ff;
	cursor: pointer;
}

.side-nav .labels span.label {
	margin-right: 5px;
	color: #737373;
}

.side-nav .labels span.label .l {
	max-width: 180px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: inline-block;
}

.user-avatar-container {
	position: relative;
	height: 30px;
	width: 30px;
}

.user-avatar-container .active-now {
	color: #29ab29;
	position: absolute;
	margin-left: 0px;
	margin-right: 0px;
	bottom: -12px;
	right: 5px;
}

.side-nav .labels span.label .e {
	vertical-align: top;
	/* display: flex; */
}

.side-nav .labels .item.email span.label {
	overflow: hidden;
	white-space: nowrap;
}

.side-nav .labels span.value {
	margin-right: 5px;
	color: #737373;
	float: right;
}

.side-nav .created_by_avatar,
.delivery-info .created_by_avatar {
	flex-basis: 40px;
}

.side-nav .owner img,
.delivery-info img {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	padding: 2px;
	border: 1px solid #d1e4fe;
	margin-right: 10px;
}

.side-nav .owner {
	justify-content: inherit;
}

.side-nav .owner .created_by_name {
	margin-top: 5px;
}

.side-nav .created_by_avatar .active-now {
	bottom: -4px;
	right: 9px;
	font-size: 10px;
}

.side-nav .labels span.positive {
	color: #619B2E;
}

.side-nav .labels.editor .label {
	width: 100%;
	margin-top: 5px;
	margin-bottom: 5px;
}

.side-nav .labels.editor .field {
	width: 100%;
}


.side-nav .labels span.number {
	color: #456CF9;
	margin-left: 5px;
}

.side-nav .labels span.negative {
	color: #D83536;
}

.user-profile.compact .up-contents {
	margin-bottom: 0px;
	padding-bottom: 0px;
}


.redactor-component[data-redactor-type=mergeTag],
.redactor-component[data-redactor-type=waiverField] {
	white-space: nowrap;
	background: rgba(0, 125, 255, 0.17);
	display: inline-block;
	padding: 3px 3px;
	line-height: 1;
	border-radius: 4px;
	cursor: pointer;
	margin-left: 1px;
	margin-right: 1px;
}


.redactor-component.waiver-field-optional-multi-line,
.redactor-component.waiver-field-required-multi-line {
	width: 100%;
	min-height: 100px;
	padding-top: 7px;
	padding-left: 10px;
}


.redactor-context-toolbar {
	margin-top: 5px;
}

.redactor-mergeTags-list,
.redactor-waiverFields-list {
	list-style: none;
	padding-inline-start: 0px;
	display: inherit;
}

.modal-body .fields {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.redactor-mergeTags-list li span,
.redactor-waiverFields-list li span {
	background: rgba(0, 125, 255, .75);
	color: #fff;
	padding: 3px 6px;
	line-height: 1;
	border-radius: 4px;
	cursor: pointer;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 3px;
	font-size: 12px;
}

.redactor-modal-header {
	background-color: #ebeef321;
	border-bottom: 1px solid #2700ff14;
	font-weight: 500;
}


.redactor-style-button a {
	padding: 8px 20px;
	background-color: #4B72FA;
	color: #fff;
	font-weight: bolder;
	font-size: 16px;
	display: inline-block;
	margin: 10px 0px;
	text-decoration: none;
	cursor: pointer;
}

.redactor-modal-box {
	font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.redactor-modal-body {
	padding: 2em;
}

.payment-add h3,
.payment-add h4,
.payment-add h5 {
	font-weight: 100;
}

.payment-add .payment-box {
	padding: 20px;
	background-color: #90afee26;
	border: 1px solid #90afee40;
	margin-top: 20px;
	margin-bottom: 20px;
}

.redactor-modal-body p.title {
	color: #cccccc;
	text-transform: uppercase;
	font-size: 10px;
	margin-bottom: 0px;
}

.redactor-component.merge-block {
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #067af80d;
	width: 100%;
	min-height: 100px;
	text-align: center;
	padding-top: 25px;
	color: #cacaca;
	border-top: 1px solid #067af82e;
	border-bottom: 1px solid #067af82e;
	border-radius: 0px;
	padding-left: 20px;
	padding-right: 20px;
	line-height: 1.3;
	white-space: normal;
}

.redactor-component.merge-tag-signature-block {
	width: 300px;
}

.redactor-component.merge-tag-signature-block::after {
	display: block;
	margin-top: 5px;
	font-size: .8em;
	content: "This block will contain your name, avatar, and contact";
}


.redactor-component.merge-tag-passenger-detail-block::after {
	display: block;
	margin-top: 5px;
	font-size: .8em;
	content: "This block will contain a detail for all passenger tickets on the trip.";
}

.redactor-component.merge-tag-order-detail-block::after {
	display: block;
	margin-top: 5px;
	font-size: .8em;
	content: "This block will contain trip purchase detail, pricing, order total, and amounts paid.";
}

.redactor-component.merge-tag-payment-ledger-block::after {
	display: block;
	margin-top: 5px;
	font-size: .8em;
	content: "This block will contain detail of all payments, refunds, offer codes, and discounts applied.";
}

.redactor-component.merge-tag-policy-block::after {
	display: block;
	margin-top: 5px;
	font-size: .8em;
	content: "This block will contain the cancellation policy, transporation directions, and special notes for the booked experience.";
}

.redactor-component.merge-tag-contact-map-block::after {
	display: block;
	margin-top: 5px;
	font-size: .8em;
	content: "This block will contain your email, phone number, website URL, and a Google Map photo of your location.";
}

.redactor-component.merge-tag-review-block::after {
	display: block;
	margin-top: 5px;
	font-size: .8em;
	content: "This block will contain your five newest 4 & 5 star reviews. Use this to build trust with shoppers";
}


.redactor-component.merge-block.redactor-component-active {
	outline-width: 1px !important;
}

.modal-dialog .chrome-message {
	margin: auto;
}

.chrome-message-body .redactor-toolbar-wrapper {
	transition-duration: .5s;
	opacity: .3;
}

.chrome-message-body .redactor-focus .redactor-toolbar-wrapper {
	display: inherit;
	opacity: 1;
}

.chrome-message-body .redactor-box.redactor-styles-on .redactor-toolbar {
	padding-left: 60px;
}

.chrome-message-body .redactor-box {
	transition-duration: .5s;
}

.chrome-message-body .redactor-box:hover {
	background-color: #067af808;
}

.chrome-message-body .redactor-box:hover .redactor-toolbar-wrapper {
	display: inherit;
	opacity: 1;
}

.chrome-message-body .redactor-focus.redactor-box {
	background-color: white;
}

.chrome-message-body .redactor-styles {
	margin-left: 50px;
	margin-right: 50px;
	padding-bottom: 15px;
}

.modal-dialog .send-bar {
	display: flex;
}

.modal-dialog .send-bar .select {
	flex-grow: 1;
}

.modal-dialog .send-bar .action {
	flex-grow: 0;
	flex-basis: 80px;
	text-align: right;
}

.cursor-pointer {
	cursor: pointer;
}

.white-button,
.white-round-button {
	background-color: white;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	color: #4B637E;
}

.white-round-button {
	border-radius: 10px;
}

.legend {
	color: #047bf8;
}

/* custom styles for date-range-picker */
.hourselect, .minuteselect {
	background-color: white !important;
	border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.no-select {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ */
  cursor: default;
}

/* iPhone 5 */
@media (max-width: 780px) and (max-height:580px) and (orientation : portrait) {
	.auth-box-w .logo-w {
		padding-bottom: 10px;
		padding-top: 0px;
	}

	.auth-box-w .logo-w img {
		max-height: 40px;
		margin-top: 0px;
	}

}


/* galaxy devices */
@media (max-width: 780px) and (max-height:640px) and (orientation : portrait) {
	.auth-box-w .logo-w {
		padding-bottom: 10px;
		padding-top: 0px;
	}

	.auth-box-w .logo-w img {
		max-height: 40px;
	}

}


/* iPhone 6/7/8 */
@media (max-width: 780px) and (max-height:700px) and (orientation : portrait) {
	.auth-box-w .logo-w {
		padding-bottom: 10px;
		padding-top: 0px;
	}

	.auth-box-w .logo-w img {
		max-height: 40px;
	}

}


/* mobile portrait */
@media (max-width: 767px) {

	.grid-parent-wrapper {
		top: 0px;
	}

	.ag-body-viewport {
		min-height: inherit;
	}


	#tripBuilderModal .modal-body {
		/* padding:0px; */
	}

	.content-box {
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}

	body.auth-wrapper:before {
		background: inherit;
		position: inherit;
	}

	.auth-wrapper {
		padding-top: 0px;

	}

	.auth-wrapper .auth-box-w {
		border-radius: 0px;
		box-shadow: inherit;
		padding: 40px;
		margin-top: 0px;
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: white;
	}

	.auth-box-w .logo-w {
		padding-left: 0px;
		/* padding-top: 0px; */
		/* padding-bottom: 20px; */
		/* padding-right: 0px; */
		/* background-color: #067bf80a; */
		border-bottom: 3px solid #067bf8a1;
	}

	.review .auth-box-w img {
		border-radius: 50%;
		border: 1px solid #067bf8;
		padding: 15px;
		max-height: 150px;
		background-color: white;
		margin-top: 0px;
	}

	.auth-box-w form {
		padding: 20px 30px;
	}

	.auth-description {
		padding-left: 30px;
		padding-right: 30px;
	}

	.auth-box-w .auth-header {
		padding-left: 0px;
		color: white;
		border-bottom: 1px solid rgba(255, 255, 255, 0.65);
		margin-bottom: 20px;
		margin-top: 20px;
		font-size: 16px;
	}

	.auth-box-w .auth-header:after {
		left: 30px;
	}

	.auth-box-w form .form-group label {
		color: white;
	}

	.menu-install-app img {
		width: 16px;
		height: 16px;
	}

	.menu-install-app .popover-body {
		font-size: 12px;
	}

	.auth-wrapper .auth-box-w {
		background-color: #003366;
		overflow-y: scroll;
	}

	.auth-box-w form .form-group .pre-icon {
		color: white;
	}

	.right-sidebar {
		max-width: 100%;
	}

	.chrome-message {
		zoom: .5;
	}

	.modal-split-bottom {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 20px;
		padding-bottom: 20px;
	}


	.setup .element-box {
		min-height: inherit;
	}

	.setup .element-box li {
		font-size: 1.2em;
	}


}

/* iPad portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1180px) and (orientation : portrait) {}

/* iPad landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1180px) and (orientation : landscape) {
	.login .auth-box-w {
		max-width: 80%;
		/* border-radius:inherit; */
		/* position:fixed; */
		top: 30px;
		/* bottom:0px; */
		/* right:0px; */
		/* left:0px; */
		animation-name: inherit;
		padding-top: 40px;
		margin-top: 20px;
	}

	.content-box {
		padding-left: 20px;
		padding-right: 20px;
	}

	.login .auth-box-w img {
		display: none !important;
	}

	body.login {
		padding-top: 0px;
	}

	body.login.background-cover-c {
		background-image: inherit;
	}


	.auth-box-w .form-group {
		width: 45%;
		display: inline-block;
	}

	.auth-box-w .form-group.password {
		margin-left: 5%;
	}

	.auth-box-w .logo-w {
		padding-top: 0px;
		padding-bottom: 20px;
		margin-top: 0px;
		display: none;
	}

	.auth-box-w .auth-header {
		margin-bottom: 10px;
	}

	.btn-lg-tablet {
		padding: 0.5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: 6px;
	}


	.auth-box-w form {
		padding-right: 0px;
	}


}